import { Dialog } from "@mui/material";
import React from "react";
import Login from "../../main-component/LoginPage/Login";
import { useLoginModal } from "../../Context/LoginModalContext";

const LoginModal = () => {
  const { loginModal, closeLoginModal } = useLoginModal();
  return (
    <Dialog open={loginModal} onClose={closeLoginModal}>
      <button className="btn-close" onClick={closeLoginModal}>
        <i className="icon-icon_close"></i>
      </button>
      <Login />
    </Dialog>
  );
};

export default LoginModal;
