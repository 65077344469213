import React, { Fragment } from "react";
import { connect } from "react-redux";
import OrderFailedSec from "../../components/OrderFailedSec";

const OrderFailed = ({ cartList, ...props }) => {
  return (
    <Fragment>
      <OrderFailedSec {...props} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    token: state.configs.token,
    w_list: state.wishList.w_list,
    configs: state.configs,
    symbol: state.data.symbol,
  };
};
export default connect(mapStateToProps)(OrderFailed);
