import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { removeFromWishList, addToCart } from "../../store/actions/action";
import { Tooltip } from "react-tooltip";
import DeleteIcon from "../../icons/DeleteIcon";
import { useLoginModal } from "../../Context/LoginModalContext";
import { checkLoggedIn } from "../../utils";
import { PulseLoader } from "react-spinners";

const WishlistPage = (props) => {
  const { w_list } = props;
  const { openLoginModal } = useLoginModal();

  const addToCartProduct = async (product, qty = 1) => {
    await props?.addToCart(product, qty);
  };

  return (
    <Fragment>
      <Navbar />
      {/* <PageTitle pageTitle={"Wishlist"} pagesub={"Wishlist"} /> */}
      <div className="cart-area sm-separator-padding">
        <div className="sm-container">
          <div className="form">
            <div className="cart-wrapper">
              <div className="heading-title">
                <h2>My Wishlist</h2>
              </div>
              <div className="row">
                <div className="col-12">
                  <form action="cart">
                    <table className="table-responsive cart-wrap w-full">
                      <thead>
                        <tr>
                          <th className="product-2">Product</th>
                          <th className="pr">Unit price</th>
                          <th className="pr">Stock</th>
                          <th className="ptice">Add</th>
                          <th className="stock">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {w_list &&
                          w_list?.length > 0 &&
                          w_list.map((wish, crt) => (
                            <WislistRow
                              wish={wish}
                              crt={crt}
                              addToCartProduct={addToCartProduct}
                              removeFromWishList={props?.removeFromWishList}
                              openLoginModal={openLoginModal}
                              {...props}
                            />
                          ))}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const WislistRow = ({
  wish,
  crt,
  addToCartProduct,
  removeFromWishList,
  openLoginModal,
  carts,
}) => {
  const inCart = carts?.find((cart) => cart.id === wish?.id);
  const [loading, setLoading] = useState(false); // Loading state

  const handleAddToCart = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await addToCartProduct(wish); // Wait for the API call to complete
      setLoading(false); // Stop loading
    } else {
      openLoginModal();
    }
  };
  return (
    <tr key={crt}>
      <td className="product">
        <ul>
          <li>
            <img
              src={wish?.proimg?.[0]?.src}
              alt={wish?.proimg?.[0]?.id}
              className="mr-10"
            />
          </li>
          <li className="first-cart">{wish.title}</li>
        </ul>
      </td>
      <td className="price">${wish.price}</td>
      <td className="stock">
        <span>{wish.stock}</span>
      </td>
      <td className="crt-btn">
        {inCart ? (
          <Link className="btn btn-primary" to={"/cart"}>
            Go To Cart
          </Link>
        ) : (
          <button
            onClick={async (e) => {
              e.preventDefault();
              await handleAddToCart(wish);
              if (checkLoggedIn()) removeFromWishList(wish.id);
            }}
            className="cart-btn-full flex justify-center items-center gap-10"
            disabled={loading}
          >
            {loading ? (
              <PulseLoader color="#fafafa" />
            ) : (
              <>
                Add To Cart
                <i className="icon-cart"></i>
              </>
            )}
          </button>
        )}
      </td>
      <td className="w-close">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            removeFromWishList(wish.id);
          }}
          data-tooltip-id="wish-tooltip"
          data-tooltip-content="Remove from Wishlist"
          data-tooltip-place="top"
        >
          <DeleteIcon />
        </button>
        <Tooltip id="wish-tooltip" />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    w_list: state.wishList.w_list,
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, { removeFromWishList, addToCart })(
  WishlistPage
);
