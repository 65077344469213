import React from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import { connect } from "react-redux";
import { totalPrice } from "../../utils";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../images/logo.png'
import search from '../../images/icon/search.png'

const Header2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const { carts } = props;
    const { wish } = props;

    const [category, setcategory] = React.useState('');

    const handleChange = (event) => {
        setcategory(event.target.value);
    };


    const [language, setlanguage] = React.useState('');
    const [currencey, setcurrencey] = React.useState('');

    const handleChange3 = (event) => {
        setlanguage(event.target.value);
    };
    const handleChange2 = (event) => {
        setcurrencey(event.target.value);
    };



    return (
        <header className="header-area header-style-3">
            <div className="header-middle">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <form onSubmit={SubmitHandler} className="middle-box">
                                <div className="category">
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            value={category}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">category</MenuItem>
                                            <MenuItem value={2}>Medicine</MenuItem>
                                            <MenuItem value={3}>Equipment</MenuItem>
                                            <MenuItem value={4}>Doctor</MenuItem>
                                            <MenuItem value={5}>Medical</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="search-box">
                                    <div className="input-group">
                                        <input type="search" className="form-control" placeholder="Search Products" />
                                        <button className="search-btn" type="submit"> <i><img
                                            src={search} alt="" /></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-3">
                            <div className="topbar-select">
                                <ul className="topbar-select-wrap">
                                    <li>
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <Select
                                                value={language}
                                                onChange={handleChange3}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">English</MenuItem>
                                                <MenuItem value={2}>Spanish</MenuItem>
                                                <MenuItem value={3}>Hindi</MenuItem>
                                                <MenuItem value={4}>Bangla</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                    <li>
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <Select
                                                value={currencey}
                                                onChange={handleChange2}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">USD</MenuItem>
                                                <MenuItem value={5}>Euro</MenuItem>
                                                <MenuItem value={6}>Rupi</MenuItem>
                                                <MenuItem value={7}>Crypto</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="middle-right">
                                <ul>
                                    <li><a href="cart.html">
                                        <i className="icon-user-1"></i>
                                        <p>
                                            <small>My Account</small>
                                            Hello, Sign in
                                        </p>
                                    </a>
                                    </li>
                                    <li><a href="wishlist.html">
                                        <i className="icon-heart"><span className="cart-count">{wish.length}</span></i>
                                        <p>
                                            <small>Favorite's</small>
                                            My Wishlist
                                        </p>
                                    </a>
                                    </li>
                                    <li><a href="cart.html">
                                        <i className="icon-cart"><span className="cart-count">{carts.length}</span></i>
                                        <p>
                                            <small>{carts.length} items</small>
                                            ${totalPrice(carts)}
                                        </p>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="header-sticky" className="menu-area">
                <div className="container-fluid">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3 col-md-7 col-sm-5 col-6">
                                <div className="navbar-header">
                                    <Link className="navbar-brand" to="/"><img src={Logo}
                                        alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-1 col-1 text-right text-xl-right d-lg-block d-none">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul className="nav">
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/home">Home Pages</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/home">Home Page 01</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/home-2">Home Page 02</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/home-3">Home Page 03</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/shop">Shop</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/shop">Shop Page</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/product-single/Hand-Sanitizer">Shop Single</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/cart">Cart Page</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/wishlist">Wishlist</Link></li>
                                                    {/* <li><Link onClick={ClickHandler} to="/compare">Compare</Link></li> */}
                                                    <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/about">Pages</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/login">My Account</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/forgot">Forgot Password</Link></li>
                                                    {/* <li><Link onClick={ClickHandler} to="/compare">Compare</Link></li> */}
                                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/home">Blog</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/blog">Blog Grid</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-right-sidebar">Blog right sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                                    <li className="third-lavel-menu"><Link onClick={ClickHandler} to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog
                                                        Single</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link onClick={ClickHandler} to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single right sidebar</Link>
                                                            </li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single left
                                                                sidebar</Link></li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single
                                                                fullwidth</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-5 text-left">
                                <div className="header-area-right">
                                    <div className="profile">
                                        <a href="tel:1234567890">
                                            <i className="icon-contact"></i>
                                            <span>
                                                {/* <small>Need help? Call us:</small>
                                                <span>(+800) 1234 5678 90 </span> */}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1">
                                <MobileMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}


const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
        wish: state.wishList.w_list,
    };
};

export default connect(mapStateToProps)(Header2);
