/* eslint-disable no-undef */
import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDeliveryCharges, mergeClasses, totalPrice } from "../../utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomPhoneInput from "../Input/CustomPhoneInput";
import AddressCard from "../Cards/AddressCard";
import axios from "axios";
import { useEffect } from "react";
import { PulseLoader } from "react-spinners";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import ReactSelect from "../UI/Select/ReactSelect";
import AddressesSection from "../AddressesSection";
import _ from "lodash";
import { useSelector } from "react-redux";
// import ReactSelect from "react-select";

const CheckoutSection = ({ cartList, updateConfigs, token, configs, ...props }) => {
  const push = useNavigate();
  const [tabs, setExpanded] = useState({ cupon: false });

  const [placeOrderLoading, setPlaceOrderLoading] = useState("");
  const totalPrices = useSelector((state) => state?.cartList?.totalPrice);
  const itemTotaleuro = useSelector((state) => state);
  console.log({ totalPrices,  itemTotaleuro });
  const handleCreateOrder = async () => {
    // if (_?.isEmpty(configs?.selectedAddress)) {
    //   toast.error("Select Address");
    //   return;
    // }
    setPlaceOrderLoading(true);
    await axios
      .post(
        "create-order",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res?.data?.status === "ok") {
          handlePayment(res?.data);
        } else {
          toast.error(res?.data?.message);
        }
        setPlaceOrderLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something Went Wrong!");
        setPlaceOrderLoading(false);
      });
  };
  async function handlePayment(data) {
    console.log({ data });

    const options = {
      channelId: "WEB",
      customerId: data?.userid,
      merchantType: "ECOMMERCE",
      merchantId: "636398956742368",
      orderID: data?.order,
      orderDescription: "Purchasing Product",
      orderAmount: data?.totalPrice,
      orderCurrency: "USD",
      email: data?.email,
      name: data?.email,
      successHandler: async function (response) {
        // Handle success response
        window.location.href = `https://neodeels.com/success/${data?.order}`;
      },
      failedHandler: async function (response) {
        // Handle failure response
        window.location.href = `https://neodeels.com/failed`;
      },
    };
    const neo = new Neo(options);
    neo.open(options);
  }
  console.log({ cartList });
  return (
    <Fragment>
      <div className="checkout-area sm-separator-padding">
        <div className="sm-container">
          <div className="checkout-wrap">
            <div className="row">
              {/* <div className="col-lg-6 col-12">
                <AddressesSection />
              </div> */}

              <div className="col-lg-10 col-12 m-auto">
                <div className="checkout-order-area">
                  <h3>Your Order</h3>
                  <div className="oreder-item">
                    <div className="title bg-title">
                      <h2>
                        Products <span>Subtotal</span>
                      </h2>
                    </div>
                    {cartList?.map((item) => (
                      <div className="oreder-product" key={item.id}>
                        <div className="images">
                          <span className="mr-10">
                            <img src={item?.proimg?.[0]?.src} alt="" />
                          </span>
                          <small>
                            {item.title} {item.iseuro ? "€" : "$"}
                            {item.price} x {item.qty}
                          </small>
                        </div>
                        {item.iseuro ? <span> ${item.qty * item.priceeuro}</span> : <span> ${item.qty * item.price}</span>}
                      </div>
                    ))}

                    <div className="title s1">
                      <h2>
                        Delivery Charge<span>${getDeliveryCharges()}</span>
                      </h2>
                    </div>
                    <div className="title s1">
                      <h2>
                        Subtotal<span>${totalPrices}</span>
                      </h2>
                    </div>
                    <div className="title s2">
                      <h2>
                        Total
                        <span>${totalPrices}</span>
                      </h2>
                    </div>
                  </div>
                  <div className="payment-option">
                    {/* <h3>Payment Method</h3>
                    <div className="payment-select">
                      <ul>
                        <li className="addToggle">
                          <input id="add" type="radio" name="payment" checked="checked" value="30" />
                          <label for="add">Direct Bank Transfer</label>
                        </li>
                        <li className="removeToggle">
                          <input id="remove" type="radio" name="payment" value="30" />
                          <label for="remove">Check payments</label>
                        </li>
                        <li className="getwayToggle">
                          <input id="getway" type="radio" name="payment" value="30" />
                          <label for="getway">Cash on delivery</label>
                        </li>
                      </ul>
                    </div> */}
                    <div className="payment-bottom">
                      <p>
                        Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described
                        in our privacy policy.
                      </p>
                      <div className="payment-btn">
                        <button className="btn-style-1" style={{ width: "100%" }} onClick={handleCreateOrder} disabled={placeOrderLoading}>
                          {placeOrderLoading ? <PulseLoader size={10} color={"#fafafa"} /> : "Place order"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default CheckoutSection;
