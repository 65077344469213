import React from "react";
import {
  ShimmerButton,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { mergeClasses } from "../../utils";

const LoadingCard = ({ thumbnailHeight = 350, className }) => {
  return (
    <div className={mergeClasses("product-item h-550", className)}>
      <div className="product-image">
        <ShimmerThumbnail height={thumbnailHeight} rounded className={"mb-0"} />
        <ul className="tag-wrap">
          {[0, 1, 2].length > 0 &&
            [0, 1, 2].map((badge, index) => {
              return <ShimmerButton key={badge} size="sm" />;
            })}
        </ul>
      </div>
      <div className="product-info">
        <ShimmerTitle className="mb-0" line={4} gap={10} />
      </div>
    </div>
  );
};

export default LoadingCard;
