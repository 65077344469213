import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { totalPrice } from "../../utils";
import "./style.scss";
import axios from "axios";
import {
  ShimmerButton,
  ShimmerCategoryList,
  ShimmerCircularImage,
  ShimmerTitle,
} from "react-shimmer-effects";
import { isEmpty } from "lodash";
const excludeKeys = ["addresid"]; // Add the keys you don't want to display here
const OrderConfirmSec = ({ carts, token, ...props }) => {
  const { orderId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getUserCartData = async () => {
    setLoading(true);
    if (error) setError(false);
    await axios
      .get("orders", {
        params: { orderid: orderId },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res?.data?.data?.[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getUserCartData();
  }, [orderId]);

  return (
    <section className="cart-recived-section separator-padding">
      <div className="container">
        {loading ? (
          <div className="row">
            <div className="order-box">
              <div className="row p-10 mt-20">
                <div className="col-2 col-xl-2">
                  <ShimmerCircularImage size={100} />
                </div>
                <div className="col-8 col-xl-8">
                  <ShimmerTitle
                    className="mb-0"
                    line={3}
                    variant={"secondary"}
                  />
                </div>
                <div className="col-2 col-xl-2">
                  <ShimmerButton size="md" />
                </div>
              </div>
              <ShimmerCategoryList items={4} categoryStyle="STYLE_SIX" />
            </div>
          </div>
        ) : !loading && error && isEmpty(data) ? (
          <div className="row">
            <div className="order-box flex items-center justify-center">
              <h1>
                Oops!
                <p>Could'nt load the data</p>
              </h1>
              <img
                src={
                  "https://cdn3d.iconscout.com/3d/premium/thumb/failed-3d-icon-download-in-png-blend-fbx-gltf-file-formats--document-folder-chat-pack-communication-icons-9316807.png?f=webp"
                }
                alt={"error-image"}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="order-box">
              <div className="order-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="green"
                  height="4em"
                  width="4em"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h2>
                  Thank You For Your Order!{" "}
                  <span>your order has been recived</span>
                </h2>
                <Link to="/" className="btn-style-1">
                  Back Home
                </Link>
              </div>
              <Grid className="cartStatus">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid className="cartTotals">
                      <h4>Order details</h4>
                      <Table>
                        <TableBody>
                          {data?.orderItems?.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={item.proimg?.[0]?.src} alt="" />{" "}
                                {item?.name}
                                {item?.price}
                              </TableCell>
                              <TableCell align="right">
                                ${item.qty * item.price}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow className="totalProduct">
                            <TableCell>Total product</TableCell>
                            <TableCell align="right">
                              {data?.orderItems?.length}
                            </TableCell>
                          </TableRow>
                          <TableRow className="totalProduct">
                            <TableCell>Delivery Charges</TableCell>
                            <TableCell align="right">
                              {data?.delivery_charges}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Total Price</b>
                            </TableCell>
                            <TableCell align="right">
                              <b>${data?.totalPrice}</b>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid className="cartTotals">
                      <h4>Shipping Address details</h4>
                      <Table>
                        <TableBody>
                          {data?.shippingAddress &&
                            Object.keys(data?.shippingAddress)
                              .filter(
                                (address) => !excludeKeys.includes(address)
                              ) // Filter out excluded keys
                              .map((address) => {
                                return (
                                  <TableRow key={address}>
                                    <TableCell>
                                      {address} {" : "}{" "}
                                      {data?.shippingAddress?.[address]}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OrderConfirmSec;
