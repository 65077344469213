import React from 'react'
import abImg from '../../images/about/img-1.jpg'
import abImg2 from '../../images/about/img-2.jpg'
import abImg3 from '../../images/about/icon.png'
import Logo from "../UiStyle/Logo";

const About = (props) => {
  return (
    <section className="about-section separator-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-img-wrap">
              <div className="about-img-top">
                <img src={"https://neo-images-1.s3.ap-northeast-2.amazonaws.com/1731328575527_551245799.png"} alt="" />
              </div>
              <div className="about-img-bottom">
                <img src={"https://neo-images-1.s3.ap-northeast-2.amazonaws.com/1731322117652_230206230.png"} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-text">
              <div className="about-icon">
                <Logo className="h-35" />
              </div>
              <h2>Neodeels – Your go-to source for exclusive gift cards and unforgettable gifting experiences</h2>
              <p>
              Neodeels is one of the biggest sites in World for buying eGifts and gift cards, offering popular brands like Esso, Walmart, Steam, Uber & Uber Eats, The Ultimate Dining Card, Gaming, Pubg, Winners, Google Play, Sony PlayStation, Amazon and more. You can find more than a hundred different eGift brands for Different countries, available from a variety of retailers and in a range of denominations, alongside our small but growing selection of electronic gift cards.!
              </p>

              <p style={{ color: "grey",opacity:"0.8" }}>(Neodeels.com is owned by NEOTECH SERVICES LLC, registered in Sharjah, UAE).</p>
              {/* <div className="row">
                                <div className="col-sm-6">
                                    <div className="about-features">
                                        <i className="icon-medical"></i>
                                        <h3>User</h3>
                                        <p>I've been a loyal customer of Neodeels for years, and their gift cards and exclusive deals always exceed my expectations! .</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="about-features">
                                        <i className="icon-medicine"></i>
                                        <h3>Gifts</h3>
                                        <p>I've been a loyal customer of Neodeels for years, and their gift cards and exclusive deals always exceed my expectations!</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;