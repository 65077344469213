import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { Grid, Button, Rating } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ComponentError from "../../components/404/ComponentError";
import ProductTabs from "./alltab";
import ReactImageGallery from "react-image-gallery";
import {
  ShimmerButton,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import "react-medium-image-zoom/dist/styles.css";
import { checkLoggedIn } from "../../utils";
import DeleteIcon from "../../icons/DeleteIcon";
import BuyNowIcon from "../../icons/BuyNowIcon";
import { MoonLoader } from "react-spinners";
import { useLoginModal } from "../../Context/LoginModalContext";

const Product = ({
  token,
  carts,
  w_list,
  addToCart,
  addToCartProduct,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  ...props
}) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { openLoginModal } = useLoginModal();

  const [singleProduct, setSingleProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const inCart = useMemo(() => {
    return carts.find((cart) => cart.id === singleProduct?.id);
  }, [carts, singleProduct]);

  const inWishList = useMemo(() => {
    return w_list?.find((wish) => wish.id === singleProduct?.id);
  }, [w_list, singleProduct]);

  const getSingleProductData = async () => {
    setLoading(true);
    if (error) setError(false);
    await axios
      .get("get-products", {
        params: { slug },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        let singleProduct = {
          ...res?.data?.data?.[0],
          id: res?.data?.data?.[0]?._id,
        };
        setSingleProduct(singleProduct);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getSingleProductData();
  }, []);

  // Function to handle adding to cart
  const handleAddToCart = async () => {
    if (checkLoggedIn()) {
      setCartLoading(true); // Start loading
      await addToCartProduct(singleProduct); // Wait for the API call to complete
      setCartLoading(false); // Stop loading
    } else {
      setCartLoading(true);
      setTimeout(() => {
        addToCartProduct(singleProduct);
        setCartLoading(false);
      }, 1000);
    }
  };

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setCartLoading(true); // Start loading
      await incrementQuantity(singleProduct.id); // Wait for the API call to complete
      setCartLoading(false); // Stop loading
    } else {
      setCartLoading(true);
      setTimeout(() => {
        incrementQuantity(singleProduct.id);
        setCartLoading(false);
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setCartLoading(true); // Start loading
      if (inCart.qty === 1) {
        await removeFromCart(singleProduct.id); // Wait for the remove action
      } else {
        await decrementQuantity(singleProduct.id); // Wait for decrement action
      }
      setCartLoading(false); // Stop loading
    } else {
      setCartLoading(true);
      if (inCart.qty === 1) {
        setTimeout(() => {
          removeFromCart(singleProduct.id);
          setCartLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          decrementQuantity(singleProduct.id);
          setCartLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <div className="product-details single-product">
      {loading ? (
        <div className="row h-full h-400">
          <div className="col-lg-5">
            <ShimmerThumbnail fitOnFrame />
          </div>
          <div className="col-lg-7">
            <ShimmerTitle line={4} gap={20} variant="primary" />
            <div className="flex gap-20">
              <ShimmerButton size="md" />
              <ShimmerButton size="md" />
            </div>
          </div>
        </div>
      ) : error && _?.isEmpty(singleProduct) ? (
        // Error state
        <ComponentError
          heading="Cannot load the Product"
          description="There Must Be Some Error In the Product Name"
        />
      ) : (
        <>
          <div className="row">
            {!_.isEmpty(singleProduct?.proimg) && (
              <div className="col-lg-5 border">
                <div className="product-single-image">
                  <ul className="tag-wrap" style={{ zIndex: 1 }}>
                    {singleProduct?.badges?.length > 0 &&
                      singleProduct?.badges?.map((badge, index) => {
                        return (
                          <li className={`tag ${badge}`} key={badge + index}>
                            {badge}
                          </li>
                        );
                      })}
                  </ul>
                  <ReactImageGallery
                    showPlayButton={false}
                    autoPlay={false}
                    items={singleProduct?.proimg?.map((image) => ({
                      original: image?.src,
                      thumbnail: image?.src,
                    }))}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-7">
              <div className="product-single-content">
                <h5>{singleProduct?.title}</h5>
                <p>{singleProduct?.description}</p>

                <div className="flex items-center gap-10 mb-10">
                  <Rating
                    name="read-only"
                    precision={0.5}
                    value={+singleProduct?.five_start_rating}
                    readOnly
                    className="mt-2"
                  />{" "}
                  <span className="mt-2">
                    {singleProduct?.five_start_rating || 0}
                  </span>
                </div>

                <span className="present-price">${singleProduct?.price}</span>
                <del className="old-price">${singleProduct?.delPrice}</del>

                {!_?.isEmpty(singleProduct?.size) && (
                  <div className="product-filter-item color filter-size">
                    {/* <div className="color-name">
                      <span>Size :</span>
                      <ul>
                        {singleProduct?.size?.map((siz) => (
                          <li className="color">
                            <input
                              id="wa1"
                              type="radio"
                              name="size"
                              value="30"
                            />
                            <label htmlFor="wa1">{siz}</label>
                          </li>
                        ))}
                      </ul>
                    </div> */}
                  </div>
                )}

                <div className="stock">
                  <ul>
                    <li>In stock : {singleProduct?.stock}</li>
                  </ul>
                </div>
                <div className="category">
                  <p className="mb-1 mt-2">
                    Category : {singleProduct?.category}
                  </p>

                  <p className="mb-1">Brand : {singleProduct?.brand}</p>
                </div>
                <div className="pro-single-btn">
                  <button
                    className="buy-now-btn me-2"
                    data-tooltip-id="buy-now-button"
                    data-tooltip-content="Buy Now"
                    data-tooltip-place="top"
                    onClick={async () => {
                      if (!checkLoggedIn()) {
                        await handleAddToCart();
                        navigate("/cart");
                      } else if (inCart) {
                        navigate("/cart");
                      } else {
                        await handleAddToCart();
                        navigate("/cart");
                      }
                    }}
                    disabled={cartLoading} // Disable button while cartLoading
                  >
                    {cartLoading ? (
                      <MoonLoader size={16} color="#fafafa" />
                    ) : (
                      <BuyNowIcon />
                    )}
                    Buy Now
                  </button>
                  {inCart ? (
                    <Grid className="flex items-center gap-10" container>
                      <button
                        className="decQtybutton"
                        onClick={handleDecrementQuantity}
                        disabled={cartLoading} // Disable button while loading
                      >
                        {cartLoading ? (
                          <MoonLoader size={16} color="#fafafa" /> // Show spinner
                        ) : inCart.qty === 1 ? (
                          <DeleteIcon />
                        ) : (
                          "-"
                        )}
                      </button>
                      <h2>{inCart?.qty}</h2>
                      <button
                        className="incQtybutton"
                        onClick={handleIncrementQuantity}
                        disabled={cartLoading} // Disable button while cartLoading
                      >
                        {cartLoading ? (
                          <MoonLoader size={16} color="#fafafa" />
                        ) : (
                          "+"
                        )}
                      </button>
                    </Grid>
                  ) : (
                    <button
                      className="cart-btn-full"
                      data-tooltip-id="cart-tooltip"
                      data-tooltip-content="add to cart"
                      data-tooltip-place="right"
                      onClick={handleAddToCart}
                      disabled={cartLoading} // Disable button while cartLoading
                    >
                      {cartLoading ? (
                        <MoonLoader size={16} color="#fafafa" />
                      ) : (
                        <i className="icon-cart"></i>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <ProductTabs /> */}
        </>
      )}
    </div>
  );
};

export default Product;
