import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import ViewModal from "../Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import erimg from "../../images/left-img.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import ProductCard from "../Cards/ProductCard";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import LoadingCard from "../Cards/LoadingCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const LatestProduct = ({
  products,
  addToCartProduct,
  addToWishListProduct,
  ...props
}) => {
  const ClickHandler = () => {
    // window.scrollTo(10, 0);
  };
  const { configs } = props;
  const discount = configs?.admin_conditions?.discount || "";
  const [open, setOpen] = React.useState(false);

  const [latestProducts, setLatestProducts] = useState([]);
  const [state, setState] = useState({});
  const [value, setValue] = React.useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 8,
    totalRecords: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const fetchLatestProducts = () => {
    setLoading(true);
    if (error) setError(false);
    axios
      .get("get-products", {
        params: { latestproducts: true, ...filter },
      })
      .then((response) => {
        const data = response?.data?.data;
        setLatestProducts(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setLatestProducts([]);
        setError(true);
        // toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLatestProducts();
  }, [filter.page]);

  return (
    <section className="latest-product-section separator-padding pt-0">
      <div className="sm-container sortable-gallery">
        <div className="latest-product-title">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <SectionTitle sTitle={"Our Latest Products"} />
            </div>
            {/* <div className="col-lg-8">
              <div className="products-filters">
                <ul>
                  <Tabs
                    value={value}
                    classes={{
                      root: "prdTab",
                      indicator: "prdTabIndicator",
                    }}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Accessories" />
                    <Tab label="Blood Pressure" />
                    <Tab label="Equipments" />
                  </Tabs>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 order-lg-2 order-1">
            {loading ? (
              <>
                <div className="grid-3 w-full">
                  {new Array(filter?.limit || 8).fill(1)?.map((el) => {
                    return <LoadingCard />;
                  })}
                </div>
                <div className="flex-justify-center-items-center pt-40 pb-40"></div>
              </>
            ) : latestProducts.length === 0 ? (
              <div>
                <h3>Oops! No Data Found!</h3>
              </div>
            ) : (
              <div className="product-wrap">
                <div className="grid-4">
                  {latestProducts?.length > 0 && (
                    <>
                      {latestProducts?.map((product, index) => (
                        <ProductCard
                          product={product}
                          key={index}
                          addToWishListProduct={addToWishListProduct}
                          addToCartProduct={addToCartProduct}
                          handleClickOpen={handleClickOpen}
                          carts={props.carts}
                          {...props}
                        />
                      ))}
                    </>
                  )}
                </div>
                {latestProducts?.length > 0 && (
                  <div className="flex-justify-center-items-center pt-20 pb-20">
                    <Pagination
                      count={Math.ceil(filter.totalRecords / filter.limit)}
                      size={"large"}
                      page={filter?.page}
                      defaultPage={filter?.page}
                      onChange={handlePageChange}
                      siblingCount={1}
                    />
                  </div>
                )}
              </div>
            )}
            {/* <div className="product-wrap">
              <TabPanel value={value} index={0}>
                <div className="row">
                  {products?.length > 0 &&
                    products?.slice(0, 6).map((product, pitem) => (
                      <div
                        className="col-xl-4 col-lg-6 col-sm-6 col-12"
                        key={pitem}
                      >
                        <div className="product-item">
                          <div className="product-image">
                            <img src={product.proImg} alt="" />
                            <ul className="tag-wrap">
                              {product.badge1 ? (
                                <li className={`tag ${product.badgeClass}`}>
                                  {product.badge1}
                                </li>
                              ) : (
                                ""
                              )}
                              {product.badge2 ? (
                                <li className={`tag ${product.badgeClass2}`}>
                                  {product.badge2}
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                            <ul className="info-wrap">
                              <li>
                                <button
                                  data-tooltip-id="wish-tooltip"
                                  data-tooltip-content="Add to Wishlist"
                                  data-tooltip-place="left"
                                  onClick={() => addToWishListProduct(product)}
                                >
                                  <i className="icon-heart"></i>
                                </button>
                                <Tooltip id="wish-tooltip" />
                              </li>
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#modal-cart-view"
                              >
                                <button
                                  data-tooltip-id="view-tooltip"
                                  data-tooltip-content="Quick View"
                                  data-tooltip-place="left"
                                  onClick={() => handleClickOpen(product)}
                                >
                                  <i className="icon-open-eye-1"></i>
                                </button>
                                <Tooltip id="view-tooltip" />
                              </li>
                            </ul>
                          </div>
                          <div className="product-info">
                            <h2>
                              <Link
                                onClick={ClickHandler}
                                to={`/product-single/${product.slug}`}
                              >
                                {product.title}
                              </Link>
                            </h2>
                            <div className="rating-product">
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                            </div>
                            <div className="price">
                              <span className="present-price">
                                ${product.price}
                              </span>
                              <del className="old-price">
                                ${product.delPrice}
                              </del>
                              <button
                                className="cart-btn"
                                data-tooltip-id="cart-tooltip"
                                data-tooltip-content="add to cart"
                                data-tooltip-place="left"
                                onClick={() => addToCartProduct(product)}
                              >
                                <i className="icon-cart"></i>
                              </button>
                              <Tooltip id="cart-tooltip" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="row">
                  {products.length > 0 &&
                    products.slice(6, 12).map((product, pitem) => (
                      <div
                        className="col-xl-4 col-lg-6 col-sm-6 col-12"
                        key={pitem}
                      >
                        <div className="product-item">
                          <div className="product-image">
                            <img src={product.proImg} alt="" />
                            <ul className="tag-wrap">
                              {product.badge1 ? (
                                <li className={`tag ${product.badgeClass}`}>
                                  {product.badge1}
                                </li>
                              ) : (
                                ""
                              )}
                              {product.badge2 ? (
                                <li className={`tag ${product.badgeClass2}`}>
                                  {product.badge2}
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                            <ul className="info-wrap">
                              <li>
                                <button
                                  data-tooltip-id="wish-tooltip"
                                  data-tooltip-content="Add to Wishlist"
                                  data-tooltip-place="left"
                                  onClick={() => addToWishListProduct(product)}
                                >
                                  <i className="icon-heart"></i>
                                </button>
                                <Tooltip id="wish-tooltip" />
                              </li>

                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#modal-cart-view"
                              >
                                <button
                                  data-tooltip-id="view-tooltip"
                                  data-tooltip-content="Quick View"
                                  data-tooltip-place="left"
                                  onClick={() => handleClickOpen(product)}
                                >
                                  <i className="icon-open-eye-1"></i>
                                </button>
                                <Tooltip id="view-tooltip" />
                              </li>
                            </ul>
                          </div>
                          <div className="product-info">
                            <h2>
                              <Link
                                onClick={ClickHandler}
                                to={`/product-single/${product.slug}`}
                              >
                                {product.title}
                              </Link>
                            </h2>
                            <div className="rating-product">
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                            </div>
                            <div className="price">
                              <span className="present-price">
                                ${product.price}
                              </span>
                              <del className="old-price">
                                ${product.delPrice}
                              </del>
                              <button
                                className="cart-btn"
                                data-tooltip-id="cart-tooltip"
                                data-tooltip-content="add to cart"
                                data-tooltip-place="left"
                                onClick={() => addToCartProduct(product)}
                              >
                                <i className="icon-cart"></i>
                              </button>
                              <Tooltip id="cart-tooltip" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="row">
                  {products.length > 0 &&
                    products.slice(12, 18).map((product, pitem) => (
                      <div
                        className="col-xl-4 col-lg-6 col-sm-6 col-12"
                        key={pitem}
                      >
                        <div className="product-item">
                          <div className="product-image">
                            <img src={product.proImg} alt="" />
                            <ul className="tag-wrap">
                              {product.badge1 ? (
                                <li className={`tag ${product.badgeClass}`}>
                                  {product.badge1}
                                </li>
                              ) : (
                                ""
                              )}
                              {product.badge2 ? (
                                <li className={`tag ${product.badgeClass2}`}>
                                  {product.badge2}
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                            <ul className="info-wrap">
                              <li>
                                <button
                                  data-tooltip-id="wish-tooltip"
                                  data-tooltip-content="Add to Wishlist"
                                  data-tooltip-place="left"
                                  onClick={() => addToWishListProduct(product)}
                                >
                                  <i className="icon-heart"></i>
                                </button>
                                <Tooltip id="wish-tooltip" />
                              </li>
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#modal-cart-view"
                              >
                                <button
                                  data-tooltip-id="view-tooltip"
                                  data-tooltip-content="Quick View"
                                  data-tooltip-place="left"
                                  onClick={() => handleClickOpen(product)}
                                >
                                  <i className="icon-open-eye-1"></i>
                                </button>
                                <Tooltip id="view-tooltip" />
                              </li>
                            </ul>
                          </div>
                          <div className="product-info">
                            <h2>
                              <Link
                                onClick={ClickHandler}
                                to={`/product-single/${product.slug}`}
                              >
                                {product.title}
                              </Link>
                            </h2>
                            <div className="rating-product">
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                              <i className="icon-star"></i>
                            </div>
                            <div className="price">
                              <span className="present-price">
                                ${product.price}
                              </span>
                              <del className="old-price">
                                ${product.delPrice}
                              </del>
                              <button
                                className="cart-btn"
                                data-tooltip-id="cart-tooltip"
                                data-tooltip-content="add to cart"
                                data-tooltip-place="left"
                                onClick={() => addToCartProduct(product)}
                              >
                                <i className="icon-cart"></i>
                              </button>
                              <Tooltip id="cart-tooltip" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
            </div> */}
          </div>
        </div>
      </div>
      <ViewModal
        addToCartProduct={addToCartProduct}
        open={open}
        onClose={handleClose}
        product={state}
        carts={props.carts}
        {...props}
      />
    </section>
  );
};

export default LatestProduct;
