import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import ViewModal from "../../Modal";
import ProductCard from "../../Cards/ProductCard";

const ProductGrid = ({
  products,
  addToCartProduct,
  addToWishListProduct,
  addToCompareListProduct,
  carts,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
  ...props
}) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [open, setOpen] = React.useState(false);

  function handleClose() {
    setOpen(false);
  }

  const [state, setState] = useState({});

  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };

  return (
    //  className="product-wrap"
    <>
      <div className="grid-3 w-full">
        {products?.length > 0 &&
          products?.map((product, index) => (
            <ProductCard
              product={product}
              key={index}
              addToWishListProduct={addToWishListProduct}
              addToCartProduct={addToCartProduct}
              handleClickOpen={handleClickOpen}
              removeFromCart={removeFromCart}
              decrementQuantity={decrementQuantity}
              incrementQuantity={incrementQuantity}
              carts={carts}
              {...props}
            />
            // <div className="col-xl-4 col-lg-6 col-sm-6 col-12" key={pitem}>
            //   <div className="product-item">
            //     <div className="product-image">
            //       <img src={product.proimg?.[0]?.src} alt="" />
            //       <ul className="tag-wrap">
            //         {product.badge1 ? (
            //           <li className={`tag ${product.badgeClass}`}>
            //             {product.badge1}
            //           </li>
            //         ) : (
            //           ""
            //         )}
            //         {product.badge2 ? (
            //           <li className={`tag ${product.badgeClass2}`}>
            //             {product.badge2}
            //           </li>
            //         ) : (
            //           ""
            //         )}
            //       </ul>
            //       <ul className="info-wrap">
            //         <li>
            //           <button
            //             data-tooltip-id="wish-tooltip"
            //             data-tooltip-content="Add to Wishlist"
            //             data-tooltip-place="left"
            //             onClick={() => addToWishListProduct(product)}
            //           >
            //             <i className="icon-heart"></i>
            //           </button>
            //           <Tooltip id="wish-tooltip" />
            //         </li>
            //         {/* <li>
            //           <button
            //             data-tooltip-id="compare-tooltip" data-tooltip-content="Add to Compare"
            //             data-tooltip-place="left"
            //             onClick={() => addToCompareListProduct(product)}
            //           >
            //             <i className="icon-left-and-right-arrows-1"></i>
            //           </button>
            //           <Tooltip id="compare-tooltip" />
            //         </li> */}
            //         <li
            //           data-bs-toggle="modal"
            //           data-bs-target="#modal-cart-view"
            //         >
            //           <button
            //             data-tooltip-id="view-tooltip"
            //             data-tooltip-content="Quick View"
            //             data-tooltip-place="left"
            //             onClick={() => handleClickOpen(product)}
            //           >
            //             <i className="icon-open-eye-1"></i>
            //           </button>
            //           <Tooltip id="view-tooltip" />
            //         </li>
            //       </ul>
            //     </div>
            //     <div className="product-info">
            //       <h2>
            //         <Link
            //           onClick={ClickHandler}
            //           to={`/product-single/${product.slug}`}
            //         >
            //           {product.title}
            //         </Link>
            //       </h2>
            //       <div className="rating-product">
            //         <i className="icon-star"></i>
            //         <i className="icon-star"></i>
            //         <i className="icon-star"></i>
            //         <i className="icon-star"></i>
            //         <i className="icon-star"></i>
            //       </div>
            //       <div className="price">
            //         <span className="present-price">${product.price}</span>
            //         <del className="old-price">${product.delPrice}</del>
            //         <button
            //           className="cart-btn-full"
            //           data-tooltip-id="cart-tooltip"
            //           data-tooltip-content="add to cart"
            //           data-tooltip-place="left"
            //           onClick={() => addToCartProduct(product)}
            //         >
            //           <i className="icon-cart"></i>
            //         </button>
            //         <Tooltip id="cart-tooltip" />
            //       </div>
            //     </div>
            //   </div>
            // </div>
          ))}
      </div>
      {/* <div className="pagination-wrapper pagination-wrapper-center">
          <ul className="pg-pagination">
              <li>
                  <Link onClick={ClickHandler} to="/shop" aria-label="Previous">
                      <i className="icon-angale-right"></i>
                  </Link>
              </li>
              <li className="active"><Link onClick={ClickHandler} to="/shop">1</Link></li>
              <li><Link onClick={ClickHandler} to="/shop">2</Link></li>
              <li><Link onClick={ClickHandler} to="/shop">3</Link></li>
              <li>
                  <Link onClick={ClickHandler} to="/shop" aria-label="Next">
                      <i className="icon-angale-right2"></i>
                  </Link>
              </li>
          </ul>
      </div> */}
      <ViewModal
        addToCartProduct={addToCartProduct}
        open={open}
        onClose={handleClose}
        product={state}
        carts={carts}
        incrementQuantity={incrementQuantity}
        removeFromCart={removeFromCart}
        decrementQuantity={decrementQuantity}
        {...props}
      />
    </>
  );
};

export default ProductGrid;
