import React from "react";
import { mergeClasses } from "../../../utils";

const CustomPaginationButton = ({ item, prevClassName }) => {
  return item?.type === "previous" ? (
    <button {...item} className={mergeClasses("custom-prev", prevClassName)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        height={18}
        width={18}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5 8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  ) : item?.type === "next" ? (
    <button {...item} className={mergeClasses("custom-next", prevClassName)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        height={18}
        width={18}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m8.25 4.5 7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  ) : null;
};

export default CustomPaginationButton;
