import { isBoolean, isNumber, isObject, isString } from "lodash";
import React from "react";
import { label } from "yet-another-react-lightbox";
const excludeKeys = ["userid", "updatedAt", "isPaid", "orderStatus"]; // Add the keys you don't want to display here

const dataObj = {
  _id: { label: "Order Id" },
  userid: { label: "User Id" },
  orderItems: { label: "Ordered Items" },
  shippingAddress: { label: "Shipping Address" },
  // orderStatus: { label: "Order Status" },
  totalPrice: { label: "Total Price" },
  isPaid: { label: "Paid" },
  paidAt: { label: "Paid At" },
  transactionId: { label: "Transaction Id" },
  method: { label: "Method" },
  message: { label: "Message" },
  paymentStatus: { label: "Payment Status" },
  deliveredAt: { label: "Delivered At" },
  delivery_charge: { label: "Delivery Charges" },
  createdAt: { label: "Created At" },
  updatedAt: { label: "Updated At" },
};

const dateConfig = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true, // This makes it 12-hour format (AM/PM)
};

const OrderCard = ({ order, handleOpen, ...props }) => {
  return (
    <div
      className="border your-orders-card p-20"
      style={{
        borderRadius: "20px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
      key={order?._id} // Use index as key
    >
      {Object.keys(order)
        .filter((key) => !excludeKeys.includes(key)) // Exclude keys from the mapping
        ?.map((key) => {
          const value = order?.[key] || {};
          if (key.includes(excludeKeys)) {
            return null;
          }
          // Check if the value is an object, skip if true
          if (isObject(value)) {
            return null;
          }
          // Check if the value is a string, number, or boolean
          if (isString(value) || isNumber(value) || isBoolean(value)) {
            let content;
            // Switch-like logic to handle different cases based on the key
            switch (key) {
              case "paymentStatus":
              case "orderStatus":
                content = <span className={value?.toLowerCase()}>{value}</span>;
                break;

              case "_id":
                content = (
                  <span className={"color-custom-purple"}>{value}</span>
                );
                break;

              case "createdAt":
                content = new Date(value).toLocaleString("en-US", dateConfig);
                break;

              case "updatedAt":
                content = new Date(value).toLocaleString("en-US", dateConfig);
                break;

              case "totalPrice":
                content = (
                  <span className="color-custom-green">{`$${value.toFixed(
                    2
                  )}`}</span>
                ); // Assuming it's a number, formatting it as currency
                break;

              case "delivery_charge":
                content = `$${value}`; // Assuming delivery_charge
                break;

              default:
                content = value;
                break;
            }

            return (
              <p key={key}>
                {dataObj?.[key]?.label} : {content}
              </p>
            );
          }

          return null; // Return null if none of the conditions match
        })}
      <div>
        <button
          className="btn btn-primary flex gap-2 justify-center items-center"
          onClick={() => handleOpen(order)}
        >
          view full details{" "}
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="m18.25 7.6-5.5-3.18a1.49 1.49 0 0 0-1.5 0L5.75 7.6c-.46.27-.75.76-.75 1.3v6.35c0 .54.29 1.03.75 1.3l5.5 3.18c.46.27 1.04.27 1.5 0l5.5-3.18c.46-.27.75-.76.75-1.3V8.9c0-.54-.29-1.03-.75-1.3zM7 14.96v-4.62l4 2.32v4.61l-4-2.31zm5-4.03L8 8.61l4-2.31 4 2.31-4 2.32zm1 6.34v-4.61l4-2.32v4.62l-4 2.31zM7 2H3.5C2.67 2 2 2.67 2 3.5V7h2V4h3V2zm10 0h3.5c.83 0 1.5.67 1.5 1.5V7h-2V4h-3V2zM7 22H3.5c-.83 0-1.5-.67-1.5-1.5V17h2v3h3v2zm10 0h3.5c.83 0 1.5-.67 1.5-1.5V17h-2v3h-3v2z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default OrderCard;
