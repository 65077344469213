import React from "react";
import { ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import { mergeClasses } from "../../utils";

const HorizontalLoadingCard = ({ className }) => {
  return (
    <div
      className={mergeClasses("horizontal-product-item mb-0 h-100", className)}
    >
      <div className="w-full h-100">
        <ShimmerThumbnail fitOnFrame className={"mb-0"} />
      </div>
      <div className="w-full h-full ml-10">
        <ShimmerTitle line={3} gap={10} className={"mb-2"} />
      </div>
    </div>
  );
};

export default HorizontalLoadingCard;
