import { ADD_CONFIGS, DELETE_CONFIGS, UPDATE_CONFIGS } from "../actions/type";

export const configsInitial = {
  isAuth: false,
  token: "",
  email: "",
  userid: "",
  fullname: "",
  ip_data: {},
  categories: [],
  brand: [],
  savedAddresses: [],
  selectedAddress: {},
};

const configsReducer = (state = configsInitial, action) => {
  switch (action.type) {
    case ADD_CONFIGS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_CONFIGS: {
      return { ...state, ...action.payload };
    }

    case DELETE_CONFIGS: {
      const deleteKey = Object.keys(action.payload)?.[0]; // Assuming there's only one key

      // Filter out the key to be deleted
      const updatedConfigs = Object.fromEntries(
        Object.entries(state).filter(([key]) => {
          return key !== deleteKey;
        })
      );

      return {
        // ...state,
        ...updatedConfigs,
      };
    }
    default:
      return state;
  }
};

export default configsReducer;
