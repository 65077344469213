import React from "react";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";

const HeaderTopbar = (props) => {
  const { configs } = props;

  const email_support = configs?.admin_conditions?.email_support || "";
  const mobile_support = configs?.admin_conditions?.mobile_support || "";

  const [language, setlanguage] = React.useState("");
  const [currencey, setcurrencey] = React.useState("");

  const handleChange = (event) => {
    setlanguage(event.target.value);
  };
  const handleChange2 = (event) => {
    setcurrencey(event.target.value);
  };

  return (
    <div className="topbar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col col-lg-3 col-md-12 col-12">
            {/* <div className="contact-link">
                            <ul>
                                <li><Link to="/contact">Quick Help</Link></li>
                                <li><Link to="/your-orders">Your Orders</Link></li>
                                <li><Link to="/wishlist">Wishlist</Link></li>
                            </ul>
                        </div> */}
          </div>
          <div className="col col-lg-6 col-md-12 col-12">
            <div className="help-link">
              <p>
                Need help?
                 {/* Call us:{" "} */}
                {/* <a href={`tel:+${mobile_support}`}>{mobile_support}</a> or */}
                {` ${email_support}`}
              </p>
            </div>
          </div>
          {/* <div className="col col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="topbar-select">
                            <ul className="topbar-select-wrap">
                                <li>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            value={language}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">English</MenuItem>
                                            <MenuItem value={2}>Spanish</MenuItem>
                                            <MenuItem value={3}>Hindi</MenuItem>
                                            <MenuItem value={4}>Bangla</MenuItem>
                                        </Select>
                                    </FormControl>
                                </li>
                                <li>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            value={currencey}
                                            onChange={handleChange2}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">USD</MenuItem>
                                            <MenuItem value={5}>Euro</MenuItem>
                                            <MenuItem value={6}>Rupi</MenuItem>
                                            <MenuItem value={7}>Crypto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </li>
                            </ul>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(HeaderTopbar);
