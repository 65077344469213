import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

const Usage = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"USAGE"} pagesub={"usage"} />

      <div className="privacy-box">
        <h3 className="pb-3">USAGE POLICY</h3>

        <p>1-The account must be registered with the full name to avoid rejecting your requests in the future.</p>
        <p>2-You can only use the e-voucher / gift card for one time.</p>
        <p>3-E-vouchers and gift cards are case sensitive. Please enter the code exactly as shown.</p>
        <p>
          4- Any form of CD keys, e-voucher, gift cards, prepaid cards that have been reviewed from Neodeels account or delivered via email or mobile message is
          not refundable or Interchangeable.
        </p>
        <p>
          5- Neodeels and its affiliates shall not be liable for wrong purchases made by yourself, due to negligence and / or the inserting false / fake
          information, which may result in damage / loss. By purchasing any e-voucher / gift card from Neodeels, you understand / admit and accept Neodeels
          disclaimer.
        </p>
        <p>
          6-When requesting direct credit shipping services, you acknowledge that you must carefully read the product description and confirm that the
          electronic goods you have purchased are correct.
        </p>
        <p>
          7-During the period of e-goods redemption, if the value of the goods is more than the amount stated in your account or in the payment process,
          Neodeels is authorized to settle the balance directly on your account.
        </p>
        <p>
          8-After delivery, Neodeels is not liable for any loss or damage to the electronic goods that were purchased through Neodeels Store, knowing that Neo
          Coupon does not bear responsibility for any invalid voucher if the purchase period exceeds 15 days.
        </p>
        <p>
          9-The program points earned under the loyalty program guide and any other source websitelied to it, and from purchases in the websitelication, have a
          validity period starting from the date of crediting the points in the user’s account and up to three (3) months. The validity period of the points
          received from the prizes provided by Neodeels Card and others are valid for two (2) weeks only. If the customers did not use the points within the
          specified period, they are automatically deducted from the user’s points balance. Customers can track the validity period through the points’ history
          shown in the websitelication.
        </p>
        <p>
          10-The Company reserves the right to modify the loyalty program at any time without prior notice, including the benefits of the loyalty program and
          the terms and conditions related to it. <br />
           - Customers are responsible for keeping abreast of the terms and conditions of the program and any modifications that may occur. Their continued
          participation in the loyalty program will constitute acceptance of such modifications.  <br />- The Company may cancel the Loyalty Program in whole or
          in part without prior notice, and all unredeemed points will be forfeited without the Company assuming any responsibility or liability. <br />
           - The Company has the right to cancel the loyalty program of any customer if it considers, according to its reasonable opinion, that he has violated
          the terms and conditions of the websitelication, committed fraudulent behavior, provided false or misleading information to the Company, or insulted
          the Company or one of its employees on any social media platform, and this customer will lose in these mentioned cases all advantages obtained by
          him/her and any right to use them. <br />
           - Neo Card has the right to partially or completely delete the balance of the wallet in case it was obtained fraudulently. Neo Card reserves the
          right to amend the balance without referring to the customer.
        </p>
        <p>11-The purchase process will be executed when the transaction is correct and unquestionable. Any purchase process on the website that is under any doubt ,incorrect or consecutively rejected, will be stopped immediately, and so will not be executed, as well as the full amount will be returned to the transferred account, without reference to the client, and the membership of the customer will be suspended, and all information is sent to Department of Cybercrime to complete legal proceedings about it.</p>
        <p>12-It takes up to three weeks to return the amount to the owner in case that the request is not executed.</p>
        <p>13-By registering on the site, you agree to receive messages containing offers and discounts for the products offered on the site.</p>
        <p>14-When purchasing from the Buy and Win section, the winner must post through his own accounts in the social media that he is the winner of the raffles, in order to ensure the transparency and credibility of Neodeels in the draw process.</p>
        <p>15-In case that the customer requests to recover the amount of his transfer from one bank to another, Neodeels does not incur commissions or transfer fees.</p>
        <p>16-The request will not be executed in case of transferring from multiple bank accounts.</p>
        <p>17-In some cases, your order may not be websiteroved for several reasons, for example if the product you want to buy is not available, or if the product is incorrectly priced, or if the order is found to be fraudulent. We will refund you for orders that are not accepted or that are canceled. Before we agree to the process, we may ask you to provide additional information, including but not limited to phone number and address or official documents that prove your identity.</p>
        <p>18-When purchasing or sending e-mail, you agree to receive any emails from us.</p>
        <p>19-Any gifts or promotional codes that customers may receive as rewards are valid for only 3 days unless otherwise stated in the advertisement details.</p>
        <p>20-If the customer uses a discount coupon in the purchase process, then no points will be calculated in that process.
        </p>
        <p>
        21-Neodeels and its affiliates have the right to make any amendments or changes to the site or websitelication and to the policies and agreements associated with the site, including the privacy policy, as well as the document for the terms and conditions of service.</p>

      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Usage;
