import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Login from "./Login";
const LoginPage = (props) => {
  return (
    <div>
      <Navbar />
      <div style={{ backgroundColor: "#1C263A" }}>
        <div className="container flex justify-center items-center p-20">
          <Login className="border" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
