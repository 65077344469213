import * as types from "./type";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  REMOVE_FROM_COMPARE_LIST,
  ADD_TO_COMPARE,
} from "./type";
import { store } from "../index";
import axios from "axios";
import { checkLoggedIn } from "../../utils";

export const logout = () => ({
  type: types.LOGOUT,
});

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const addConfigs = (data) => ({
  type: types.ADD_CONFIGS,
  payload: data,
});

export const updateConfigs = (data) => ({
  type: types.UPDATE_CONFIGS,
  payload: data,
});

export const deleteConfigs = (data) => ({
  type: types.DELETE_CONFIGS,
  payload: data,
});
export const addUserCart = (userData) => async (dispatch) => {
  dispatch({
    type: types.ADD_USER_CART,
    userData,
  });
};

// Add product to cart with API call
export const addToCart = (product, qty, color, size) => async (dispatch) => {
  if (checkLoggedIn()) {
    try {
      const body = {
        productid: product._id,
        quantity: 1,
        // color,
        // size,
      };
      // Make API call to add the item to the cart
      const response = await axios.post(
        "add-cart",
        { products: [body] },
        {
          headers: {
            Authorization: `Bearer ${store?.getState()?.configs?.token}`,
          },
        }
      );
      // Dispatch Redux action to add the product locally after successful API call
      if (response?.data?.status === "ok") {
        toast.success(`${product.title} Added to Cart`);
        dispatch({
          type: types.ADD_TO_CART,
          product,
          qty,
          color,
          size,
        });
      }
    } catch (error) {
      toast.error("Failed to add item to cart");
    }
  } else {
    toast.success(`${product.title} Added to Cart`);
    dispatch({
      type: types.ADD_TO_CART,
      product,
      qty,
      color,
      size,
    });
  }
};

// Increment quantity in cart with API call
export const incrementQuantity = (product_id) => async (dispatch) => {
  if (checkLoggedIn()) {
    try {
      const body = {
        productid: product_id,
        action: "increase",
      };

      // Make API call to increment the quantity
      const response = await axios.post("update-cart", body, {
        headers: {
          Authorization: `Bearer ${store?.getState()?.configs?.token}`,
        },
      });

      // Dispatch Redux action to increment the quantity locally after successful API call
      if (response?.data?.status === "ok") {
        dispatch({
          type: types.INCREMENT_QUANTITY,
          product_id,
        });
      }
    } catch (error) {
      toast.error("Failed to increment quantity");
    }
  } else {
    dispatch({
      type: types.INCREMENT_QUANTITY,
      product_id,
    });
  }
};

// Decrement quantity in cart with API call
export const decrementQuantity = (product_id) => async (dispatch) => {
  if (checkLoggedIn) {
    try {
      const body = {
        productid: product_id,
        action: "decrease",
      };

      // Make API call to decrement the quantity
      const response = await axios.post("update-cart", body, {
        headers: {
          Authorization: `Bearer ${store?.getState()?.configs?.token}`,
        },
      });

      // Dispatch Redux action to decrement the quantity locally after successful API call
      if (response?.data?.status === "ok") {
        dispatch({
          type: types.DECREMENT_QUANTITY,
          product_id,
        });
      }
    } catch (error) {
      toast.error("Failed to decrement quantity");
    }
  } else {
    dispatch({
      type: types.DECREMENT_QUANTITY,
      product_id,
    });
  }
};

// Remove from cart
export const removeFromCart = (product_id) => async (dispatch) => {
  if (checkLoggedIn()) {
    try {
      const body = {
        productid: product_id,
        action: "remove",
      };

      // Make API call to remove the item from the cart
      const response = await axios.post("update-cart", body, {
        headers: {
          Authorization: `Bearer ${store?.getState()?.configs?.token}`,
        },
      });

      // Dispatch Redux action to remove the product locally after successful API call
      if (response?.data?.status === "ok") {
        toast.success("Item Removed from Cart");
        dispatch({
          type: types.REMOVE_FROM_CART,
          product_id,
        });
      }
    } catch (error) {
      toast.error("Failed to remove item from cart");
    }
  } else {
    toast.success("Item Removed from Cart");
    dispatch({
      type: types.REMOVE_FROM_CART,
      product_id,
    });
  }
};

export const addToWishList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_WISHLIST,
    product,
  });
};

export const removeFromWishList = (id) => (dispatch) => {
  toast.error("Item removed from WishList");
  dispatch({
    type: REMOVE_FROM_WISHLIST,
    id,
  });
};

export const addToCompareList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_COMPARE,
    product,
  });
};
export const removeFromCompareList = (product) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_COMPARE_LIST,
    product,
  });
};
