import { Grid } from "@mui/material";
import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DeleteIcon from "../../icons/DeleteIcon";
import { checkLoggedIn, mergeClasses } from "../../utils";
import HeartCloseIcon from "../../icons/HeartCloseicon";
import { MoonLoader } from "react-spinners"; // For loading spinner
import BuyNowIcon from "../../icons/BuyNowIcon";
import { useLoginModal } from "../../Context/LoginModalContext";

const ProductCard = ({
  product,
  key,
  addToWishListProduct,
  addToCartProduct,
  handleClickOpen,
  carts,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
  className,
  w_list,
  ...props
}) => {
  const [loading, setLoading] = useState(false); // Loading state

  const { badges } = product;
  const navigate = useNavigate();
  const { openLoginModal } = useLoginModal();
  const productImage = useMemo(() => product.proimg?.[0], [product.proimg]);

  const inCart = useMemo(() => {
    return carts.find((cart) => cart.id === product?.id);
  }, [carts, product]);

  const inWishList = useMemo(() => {
    return w_list?.find((wish) => wish.id === product?.id);
  }, [w_list, product]);

  // Function to handle adding to cart
  const handleAddToCart = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await addToCartProduct(product); // Wait for the API call to complete
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      setTimeout(() => {
        addToCartProduct(product);
        setLoading(false);
      }, 1000);
    }
  };

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await incrementQuantity(product.id); // Wait for the API call to complete
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      setTimeout(() => {
        incrementQuantity(product.id);
        setLoading(false);
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      if (inCart.qty === 1) {
        await removeFromCart(product.id); // Wait for the remove action
      } else {
        await decrementQuantity(product.id); // Wait for decrement action
      }
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      if (inCart.qty === 1) {
        setTimeout(() => {
          removeFromCart(product.id);
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          decrementQuantity(product.id);
          setLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <div
      className={mergeClasses(
        "col-xl-12 col-lg-12 col-sm-12 col-12 h-550",
        className
      )}
      key={key}
    >
      <div className="product-item">
        <div className="product-image">
          <img src={productImage?.src} alt={productImage?.id} />
          <ul className="tag-wrap">
            {badges.length > 0 &&
              badges.map((badge, index) => {
                return (
                  <li className={`tag ${badge}`} key={badge + index}>
                    {badge}
                  </li>
                );
              })}
          </ul>
          <ul className="info-wrap">
            {inWishList ? (
              <li>
                <button
                  data-tooltip-id="wish-tooltip"
                  data-tooltip-content="Remove From Wishlist"
                  data-tooltip-place="left"
                  onClick={() => addToWishListProduct(product)}
                >
                  <HeartCloseIcon />
                </button>
                <Tooltip id="wish-tooltip" />
              </li>
            ) : (
              <li>
                <button
                  data-tooltip-id="wish-tooltip"
                  data-tooltip-content="Add to Wishlist"
                  data-tooltip-place="left"
                  onClick={() => addToWishListProduct(product)}
                >
                  <i className="icon-heart"></i>
                </button>
                <Tooltip id="wish-tooltip" />
              </li>
            )}

            <li data-bs-toggle="modal" data-bs-target="#modal-cart-view">
              <button
                data-tooltip-id="view-tooltip"
                data-tooltip-content="Quick View"
                data-tooltip-place="left"
                onClick={
                  handleClickOpen ? () => handleClickOpen(product) : () => {}
                }
              >
                <i className="icon-open-eye-1"></i>
              </button>
              <Tooltip id="view-tooltip" />
            </li>
          </ul>
        </div>
        <div className="product-info">
          <div className="product-card-title">
            <h2
              data-tooltip-id="title-tooltip"
              data-tooltip-content={product?.title}
              data-tooltip-place="top"
            >
              <Link to={`/product-single/${product.slug}`}>
                {product.title}
              </Link>
              <Tooltip id="title-tooltip" />
            </h2>
          </div>

          <div className="w-full">
            <h6>Category : {product?.category}</h6>
          </div>
          <div className="w-full">
            <h6>Brand : {product?.brand}</h6>
          </div>

          <div className="product-card-footer">
            <div className="w-full">
              <span className="present-price">{product?.iseuro?"€":"$"}{product?.price}</span>
              <del className="old-price"> {product?.iseuro?"€":"$"}{product?.delPrice}</del>
            </div>
          </div>
          <div className="flex items-center mt-2">
            <div style={{ width: "40%" }} className="flex ">
              <button
                className="buy-now-btn"
                data-tooltip-id="buy-now-button"
                data-tooltip-content="Buy Now"
                data-tooltip-place="top"
                onClick={async () => {
                  if (!checkLoggedIn()) {
                    await handleAddToCart();
                    navigate("/cart");
                  } else if (inCart) {
                    navigate("/cart");
                  } else {
                    await handleAddToCart();
                    navigate("/cart");
                  }
                }}
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <MoonLoader size={16} color="#fafafa" />
                ) : (
                  <BuyNowIcon />
                )}
                Buy Now
              </button>
            </div>
            <div style={{ width: "60%" }}>
              {inCart ? (
                <Grid
                  className="flex justify-end items-center gap-10 "
                  container
                >
                  <button
                    className="decQtybutton"
                    onClick={handleDecrementQuantity}
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <MoonLoader size={16} color="#fafafa" /> // Show spinner
                    ) : inCart.qty === 1 ? (
                      <DeleteIcon />
                    ) : (
                      "-"
                    )}
                  </button>
                  <h2>{inCart?.qty}</h2>
                  <button
                    className="incQtybutton"
                    onClick={handleIncrementQuantity}
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? <MoonLoader size={16} color="#fafafa" /> : "+"}
                  </button>
                </Grid>
              ) : (
                <button
                  className="cart-btn-full"
                  onClick={handleAddToCart}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? (
                    <MoonLoader size={16} color="#fafafa" />
                  ) : (
                    <i className="icon-cart"></i>
                  )}
                </button>
              )}
            </div>
          </div>
          <Tooltip id="buy-now-button" />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
