import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

const RefundReturnForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"REFUND & RETURN"} pagesub={"REFUND AND RETURN POLICY"} />

      <div className="privacy-box">
        <h3 className="pb-3">REFUND AND RETURN POLICY</h3>

        <p>
          All of our digital vouchers (“Digital Voucher/s”) are digital codes which cannot be canceled, returned or refunded once purchased and delivered to you
          or the customer. So please make sure before purchasing to choose the right product, with the correct store country, in the correct currency and
          payment method.
        </p>
        <p>
          For purchased Digital Vouchers, refunds will only be applicable if, for a reason caused by Neodeels.com, Neodeels.com does not deliver a valid ordered
          Digital Voucher to the customer’s registered account, or/and registered email address. In this case refunds will be done only through the original
          mode of payment.
        </p>
        <p>
          For a purchased Digital Voucher, Neodeels.com will deliver the Digital Voucher to the customer’s account within 24 hours after the payment is
          successful. If you do not receive the purchased Digital Vouchers within 24 hours of successful payment, you must submit a complaint through our
          website. Failure to submit a complaint within this timeframe will be interpreted as the Digital Voucher being successfully delivered, and you will no
          longer be eligible to claim the Digital Vouchers or a to claim a refund.
        </p>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default RefundReturnForm;
