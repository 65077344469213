import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import Pagination from "@mui/material/Pagination";
import LoadingCard from "../Cards/LoadingCard";

const FilterAllProduct = ({
  loading,
  products,
  addToCartProduct,
  addToWishListProduct,
  addToCompareListProduct,
  page,
  handlePageChange,
  carts,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
  onRegionChange,
  filter,
  iseuro,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="col-lg-9">
      {loading ? (
        <>
          <div className="grid-3 w-full">
            {new Array(filter?.limit || 8).fill(1)?.map((el) => {
              return <LoadingCard />;
            })}
          </div>
          <div className="flex-justify-center-items-center pt-40 pb-40"></div>
        </>
      ) : products.length === 0 ? (
        <div>
          <h3>Oops! No Data Found!</h3>
          <p>Try going with Different filters to get the data.</p>
        </div>
      ) : (
        <div>
          {/* <div className="shop-section-top-inner">
            <div className="shoping-list">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <i className="icon-svgexport"></i>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <i className="icon-svgexport2"></i>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="shoping-product">
              <span>Showing Products 1 - 9 Of 9 Result</span>
            </div>
          </div> */}
          {/* <TabContent activeTab={activeTab}> */}
          {/* <TabPane tabId="1"> */}
          <div className="select-eu-box">
            <select value={iseuro ? "eu" : "global"} onChange={(e) => onRegionChange(e.target.value)}>
              <option>please select country</option>
              <option value="eu">For EU</option>
              <option value="global">Global</option>
            </select>
          </div>
          <ProductGrid
            addToCartProduct={addToCartProduct}
            addToWishListProduct={addToWishListProduct}
            addToCompareListProduct={addToCompareListProduct}
            products={products}
            carts={carts}
            removeFromCart={removeFromCart}
            decrementQuantity={decrementQuantity}
            incrementQuantity={incrementQuantity}
            {...props}
          />
          <div className="flex-justify-center-items-center pt-20 pb-20">
            <Pagination
              count={Math.ceil(filter.totalRecords / filter.limit)}
              size={"large"}
              page={+filter?.page}
              defaultPage={+filter?.page}
              onChange={handlePageChange}
            />
          </div>
          {/* </TabPane> */}

          {/* <TabPane tabId="2">
            <ProductList
              addToCartProduct={addToCartProduct}
              addToWishListProduct={addToWishListProduct}
              addToCompareListProduct={addToCompareListProduct}
              products={products}
            />
          </TabPane> */}
          {/* </TabContent> */}
        </div>
      )}
    </div>
  );
};

export default FilterAllProduct;
