import React from "react";
import { Link } from "react-router-dom";
import mail from "../../images/icon/mail.png";
import payment from "../../images/logo card.png";
import OfferFeaturesSection from "../OfferFeaturesSection/OfferFeaturesSection";
import Logo from "../UiStyle/Logo";
import Discountbar from "../Discountbar/Discountbar";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  return (
    <>
      {/* <Discountbar /> */}
      <HeaderTopbar />
      <footer className={`footer-section ${props.ftClass}`}>
        <OfferFeaturesSection />
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="widget about-widget">
                  <div className="widget-logo">
                    <Logo to={"/"} />
                  </div>
                  <p>
                    Neodeels is a top destination for eGifts and gift cards, featuring popular brands like Walmart, Steam, Uber, Amazon, and more across
                    multiple countries. Find hundreds of eGift options and a growing selection of electronic gift cards{" "}
                  </p>
                </div>
              </div>
              {/* <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Information</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/shop">
                      Delivery
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/checkout">
                      Secure Payment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Sitemap
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/shop">
                      Stores
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
              <div className="col col-xl-3  col-lg-2 col-md-2 col-sm-12 col-12">
                <div className="widget link-widget custom">
                  <div className="widget-title">
                    <h3>SHOP</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/shop">
                        New Products
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/shop">
                        Best Sales
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/login">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/login">
                        My Account
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-xl-3  col-lg-2 col-md-2 col-sm-12 col-12 ">
                <div className="widget link-widget custom">
                  <div className="widget-title">
                    <h3>COMPANY</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-xl-23 col-lg-2 col-md-2 col-sm-12 col-12">
                <div className="widget link-widget custom">
                  <div className="widget-title">
                    <h3>HELP</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/privacy">
                      Legal & Privacy
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/rr">
                      Return & refund
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/tos">
                      Terms of service
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/shipping">
                      Usage policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i>
                        <img src={mail} alt="" />
                      </i>
                      Email: admin@neodeels.com
                    </li>
                   
                    
                    <li>
                      {" "}
                      <i className="icon-time"></i> Hours 10:00 - 18:00, Mon -
                      Sat
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget subscribe">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>I have been a loyal customer of this auto parts company for years and I cannot
                                    recommend them enough. </p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="form-field">
                                        <input type="email" placeholder="Enter your mail" id="semail" required />
                                        <button type="submit" className="btn-style-1">Submit Now</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
        <div className="lower-footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-4 col-12">
                <div className="copy-left">
                  <p className="copyright">2024 &copy; all right reserved by Neotech Services LLC</p>
                </div>
              </div>
              <div className="col col-lg-8 col-12">
                <ul className="lower-footer-link">
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <img src={payment} alt="" height={"30px"} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
