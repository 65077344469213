import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.scss";

const CustomPhoneInput = ({
  value = "123",
  onChange,
  country = "in",
  excludeCountries = ["ae"], // removed countries
  // onlyCountries = ["in"],
  searchStyle = { width: "95%", height: "30px" },
  enableSearch = true,
  inputStyle = {
    width: "100%",
    backgroundColor: "#f2f2f2",
    border: "2px solid transparent",
  },
  specialLabel = "",
  placeholder = "123-456-7890",
  ...rest
}) => {
  return (
    <PhoneInput
      country={country}
      value={value}
      onChange={onChange}
      // onlyCountries={onlyCountries}
      excludeCountries={excludeCountries}
      searchStyle={searchStyle}
      enableSearch={enableSearch}
      inputStyle={inputStyle}
      specialLabel={specialLabel}
      placeholder={placeholder}
      containerClass="custom-phone-input"
      {...rest} // Spread the rest of the props
    />
  );
};

export default CustomPhoneInput;
