import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addUserCart } from "../store/actions/action";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const token = useSelector((store) => store?.configs?.token);
  const dispatch = useDispatch();
  const [cart, setCart] = useState({
    loading: false,
    error: false,
  });

  const updateCartLoaders = (object) => {
    const keys = Object.keys(object);
    const missingKeys = keys.filter((key) => !(key in cart));

    if (missingKeys.length > 0) {
      toast.error(`The keys ${missingKeys.join(", ")} are not available`);
    } else {
      setCart((prev) => ({ ...prev, ...object }));
    }
  };

  const getUserCartData = async () => {
    updateCartLoaders({ loading: true });
    if (cart?.error) updateCartLoaders({ error: false });
    await axios
      .get("get-cart", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        updateCartLoaders({ loading: false });
        dispatch(
          addUserCart({
            cart: res?.data?.cartDetails,
            totalPrice: res?.data?.totalPrice,
            itemTotaleuro: res?.data?.itemTotaleuro,
            totalQuantity: res?.data?.totalQuantity,
          })
        );
      })
      .catch((err) => {
        updateCartLoaders({ loading: false, error: true });
      });
  };

  let valueData = { cart, updateCartLoaders, getUserCartData };

  return (
    <CartContext.Provider value={valueData}>{children}</CartContext.Provider>
  );
};
