// SearchModalContext.js
import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";

// Create the context
const SearchModalContext = createContext();

// Export a custom hook for consuming the context
export const useSearchModal = () => useContext(SearchModalContext);

// Context provider component
export const SearchModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);

  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchFilter, setSearchFilter] = useState({
    page: 1,
    limit: 3,
    totalRecords: null,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const searchProductsFunc = async () => {
    setSearchLoading(true);
    if (searchError) setSearchError(false);
    await axios
      .get("get-products", { params: { title: searchQuery, ...searchFilter } })
      .then((res) => {
        let data = res?.data?.data?.map((el) => ({ ...el, id: el._id }));
        setSearchedProducts(data);
        setSearchFilter((prev) => ({
          ...prev,
          totalRecords: res.data.totalRecords,
        }));
        setSearchLoading(false);
      })
      .catch((err) => {
        setSearchLoading(false);
        setSearchedProducts([]);
        setSearchError(true);
        // toast.error(err?.data?.message);
      });
  };

  const handleSearchPageChange = (event, value) => {
    setSearchFilter((prev) => ({ ...prev, page: value }));
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setSearchedProducts([]);
      setSearchFilter({
        page: 1,
        limit: 3,
        totalRecords: null,
      });
      setSearchLoading(false);
      setSearchError(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchFilter.page > 1) {
      searchProductsFunc();
    }
  }, [searchFilter.page]);

  let valueData = {
    isModalOpen,
    setIsModalOpen,
    searchQuery,
    setSearchQuery,
    searchLoading,
    setSearchLoading,
    searchError,
    setSearchError,
    searchedProducts,
    setSearchedProducts,
    openModal,
    closeModal,
    searchProductsFunc,
    searchFilter,
    setSearchFilter,
    handleSearchPageChange,
  };

  return (
    <SearchModalContext.Provider value={valueData}>
      {children}
    </SearchModalContext.Provider>
  );
};
