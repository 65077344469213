import React from "react";

function HeartCloseIcon({ size = "18px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M3 3l18 18M19.5 12.572L18 14m-2 2l-4 4-7.5-7.428a5 5 0 01-1.288-5.068A4.976 4.976 0 015 5m3-1c1.56 0 3.05.727 4 2a5 5 0 117.5 6.572"
      ></path>
    </svg>
  );
}

export default HeartCloseIcon;
