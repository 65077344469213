import React from "react";
import { Tooltip } from "react-tooltip";
import DeleteIcon from "../../icons/DeleteIcon";

const AddressCard = ({
  address,
  onEditClick,
  onDeleteClick,
  selectedAddress,
  configs,
}) => {
  return (
    <div className="flex justify-between items-center product-item">
      <div
        className="product-image"
        style={{ textAlign: "left", border: "none" }}
      >
        {(selectedAddress?._id === address?._id ||
          configs?.selectedAddress?._id === address?._id) && (
          <ul className="info-wrap">
            <li>
              <button
                data-tooltip-id="wish-tooltip"
                data-tooltip-content="Edit Address"
                data-tooltip-place="left"
                onClick={onEditClick ? onEditClick : () => {}}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0" // Fix typo here, should be `strokeWidth`
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M3 10h11v2H3v-2zm0-2h11V6H3v2zm0 8h7v-2H3v2zm15.01-3.13.71-.71a.996.996 0 0 1 1.41 0l.71.71c.39.39.39 1.02 0 1.41l-.71.71-2.12-2.12zm-.71.71-5.3 5.3V21h2.12l5.3-5.3-2.12-2.12z"></path>
                </svg>
              </button>
              <Tooltip id="wish-tooltip" />
            </li>

            <li data-bs-toggle="modal" data-bs-target="#modal-cart-view">
              <button
                data-tooltip-id="view-tooltip"
                data-tooltip-content="Quick View"
                data-tooltip-place="left"
                onClick={onDeleteClick ? onDeleteClick : () => {}}
              >
                <DeleteIcon />
              </button>
              <Tooltip id="view-tooltip" />
            </li>
          </ul>
        )}
        <div className="form-style row p-1 m-1">
          <div className="col-lg-6 col-md-12 col-12">
            <label>Name : {address.fullname}</label>
          </div>

          <div className="col-lg-6 col-md-12 col-12">
            <label>Phone : {address.phoneNumber}</label>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <label>Street address : {address.street}</label>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <label>Street address 2 :{address.address2}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>Landmark : {address.landmark}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>Type : {address.type}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>Country : {address.country}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>State {address.state}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>City :{address.city}</label>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <label>Zip / Postal code : address.postCode</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
