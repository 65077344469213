import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";

import Footer from "../../components/footer/Footer";
import Register from "./Register";
const RegisterPage = (props) => {
  return (
    <div>
      <Navbar />
      <PageTitle pageTitle={"My account"} pagesub={"My account"} />
      <div className="container">
        <Register />
      </div>
      <Footer />
    </div>
  );
};

export default RegisterPage;
