import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children }) {
  const isAuth = useSelector((state) => state.configs.isAuth);
  const token = useSelector((state) => state.configs.token);

  const location = useLocation();

  const from = {
    pathname: location.pathname,
  };
  if (isAuth && token) return children;
  return <Navigate to={"/login"} state={from} replace />;
}

export default ProtectedRoute;
