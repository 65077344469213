import React, { useState } from "react";
import { checkLoggedIn } from "../../utils";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../icons/DeleteIcon";
import { MoonLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";

const CartProductRow = ({ cartItem, getUserCartData, key, ...props }) => {
  console.log("🚀 ~ CartProductRow ~ props:", props);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await props?.incrementQuantity(cartItem.id); // Wait for the API call to complete
      getUserCartData();
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      setTimeout(() => {
        props?.incrementQuantity(cartItem.id);
        getUserCartData();
        setLoading(false);
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      if (cartItem.qty === 1) {
        await props?.removeFromCart(cartItem.id); // Wait for the remove action
        getUserCartData();
      } else {
        await props?.decrementQuantity(cartItem.id); // Wait for decrement action
        getUserCartData();
      }
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      if (cartItem.qty === 1) {
        setTimeout(() => {
          props?.removeFromCart(cartItem.id);
          getUserCartData();
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          props?.decrementQuantity(cartItem.id);
          getUserCartData();
          setLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <tr key={key}>
      <td className="product">
        <ul>
          <li>
            <img src={cartItem?.proimg?.[0]?.src} alt={cartItem?.proimg?.[0]?.id} />
          </li>
          <li className="cart-item-title">{cartItem.title}</li>
        </ul>
      </td>
      <td className="price">
        {cartItem?.iseuro ? "€" : "$"}
        {cartItem.price}
      </td>
      <td className="quantity">
        <ul className="input-style flex gap-10">
          <button
            className="decQtybutton"
            onClick={(e) => {
              e.preventDefault();
              handleDecrementQuantity();
            }}
            disabled={loading}
          >
            {loading ? (
              <MoonLoader size={16} color="#fafafa" /> // Show spinner
            ) : cartItem.qty === 1 ? (
              <DeleteIcon />
            ) : (
              "-"
            )}
          </button>
          {cartItem.qty}
          <button
            className="incQtybutton"
            onClick={(e) => {
              e.preventDefault();
              handleIncrementQuantity();
            }}
            disabled={loading}
          >
            {loading ? <MoonLoader size={16} color="#fafafa" /> : "+"}
          </button>
        </ul>
      </td>
      <td className="subtotal">${cartItem.itemTotal }</td>
      <td className="actions">
        <button
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            await props?.removeFromCart(cartItem.id);
            setLoading(false);
          }}
          data-tooltip-id="wish-tooltip"
          data-tooltip-content="Remove from Cart"
          data-tooltip-place="top"
          className="incQtybutton"
        >
          <DeleteIcon />
        </button>
        <Tooltip id="cart-tooltip" />
      </td>
    </tr>
  );
};

export default CartProductRow;
