import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import {
  addToCart,
  addToWishList,
  addToCompareList,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "../../store/actions/action";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FilterSidebar from "../../components/FilterSidebar";
import FilterAllProduct from "../../components/FilterAllProduct";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import _, { floor, flowRight } from "lodash";

const ShopPage = ({ addToCart, addToWishList, addToCompareList, carts, ...props }) => {
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsError, setProductsError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    size: searchParams.get("size") || "",
    category: searchParams.get("category") || "",
    brand: searchParams.get("brand") || "",
    page: searchParams.get("page") || 1,
    price: searchParams.get("price") || "",
    maxPrice: searchParams.get("maxPrice") || "",
    minPrice: searchParams.get("minPrice") || "",
    iseuro: searchParams.get("iseuro") || "",
    limit: 12,
  });
  const getPrevParams = () => {
    return Object.keys(filter)?.reduce((acc, key) => {
      if (searchParams.get(key)) {
        acc[key] = searchParams.get(key);
        return acc;
      } else {
        return acc;
      }
    }, {});
  };

  const handlePageChange = (event, value) => {
    setFilter((prev) => {
      return { ...prev, page: value };
    });
    let prevousPrams = getPrevParams();
    setSearchParams({ ...prevousPrams, page: value });
  };

  const priceChangeHandler = (clickedObject) => {
    const newParams = new URLSearchParams(searchParams);
    if (_?.isEmpty(clickedObject)) {
      setFilter({
        ...filter,
        page: 1,
        minPrice: "",
        maxPrice: "",
      });
      newParams.delete("minPrice");
      newParams.delete("maxPrice");
      setSearchParams(newParams);
      return;
    }

    // Set new filter state for price
    setFilter({
      ...filter,
      page: 1,
      minPrice: clickedObject?.minPrice || "",
      maxPrice: clickedObject?.maxPrice !== null ? clickedObject?.maxPrice : "",
    });

    // Update URL params for minPrice
    if (clickedObject?.minPrice) {
      newParams.set("minPrice", clickedObject?.minPrice);
    } else {
      newParams.delete("minPrice");
    }

    // Update URL params for maxPrice, but remove if maxPrice is null (i.e., 200 and above case)
    if (clickedObject?.maxPrice !== null) {
      newParams.set("maxPrice", clickedObject?.maxPrice);
    } else {
      newParams.delete("maxPrice"); // Remove maxPrice if it's "and more" case
    }

    // Set updated search params
    setSearchParams(newParams);
  };

  const changeHandler = ({ target: { name, value } }) => {
    let prevousPrams = getPrevParams();
    setSearchParams({ ...prevousPrams, page: 1, [name]: value });
    setFilter((prev) => {
      return { ...prev, page: 1, [name]: value };
    });
  };
  // const priceFIlter = (price) => {
  //   if (filter.price === "") {
  //     return true;
  //   } else if (filter.price.max && filter.price.min) {
  //     return price <= filter.price.max && price >= filter.price.min;
  //   } else if (filter.price.min) {
  //     return price >= filter.price.min;
  //   } else {
  //     return false;
  //   }
  // };

  const addToCartProduct = async (product) => {
    await addToCart(product, 1, filter.category, filter.size);
  };
  const onRegionChange = async (value) => {
    console.log({ value });
    let prevousPrams = getPrevParams();
    if (value == "eu") {
      setFilter((prev) => {
        return { ...prev, page: 1, iseuro: true };
      });
      setSearchParams({ ...prevousPrams, iseuro: "true", page: 1 });
    } else {
      setFilter((prev) => {
        return { ...prev, page: 1, iseuro: "" };
      });

      searchParams.delete("iseuro");
      setSearchParams(searchParams);
      fetchProducts();
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [filter.price, filter.size, filter.category, filter.iseuro, filter?.brand, filter?.page, filter?.minPrice, filter?.maxPrice]);

  const fetchProducts = () => {
    setProductsLoading(true);
    if (productsError) setProductsError(false);
    axios
      .get("get-products", { params: filter })
      .then((response) => {
        const data = response?.data?.data;
        setProducts(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setProducts([]);
        setProductsError(true);
        // toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setProductsLoading(false);
      });
  };

  const addToWishListProduct = (product, qty = 1) => {
    addToWishList(product, qty);
  };
  const addToCompareListProduct = (product, qty = 1) => {
    addToCompareList(product, qty);
  };
  return (
    <Fragment>
      <Navbar />
      {/* <PageTitle pageTitle={"Shop"} pagesub={"Shop"} /> */}
      <div className="shop-section">
        <div className="sm-container">
          <div className="row">
            <FilterSidebar
              filter={filter}
              priceChangeHandler={priceChangeHandler}
              addToCompareListProduct={addToCompareListProduct}
              changeHandler={changeHandler}
              {...props}
            />
            <FilterAllProduct
              addToCartProduct={addToCartProduct}
              addToWishListProduct={addToWishListProduct}
              addToCompareListProduct={addToCompareListProduct}
              products={products}
              page={filter?.page}
              handlePageChange={handlePageChange}
              carts={carts}
              removeFromCart={removeFromCart}
              decrementQuantity={decrementQuantity}
              incrementQuantity={incrementQuantity}
              loading={productsLoading}
              filter={filter}
              iseuro={filter?.iseuro}
              onRegionChange={onRegionChange}
              {...props}
            />
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps, {
  addToCart,
  addToWishList,
  addToCompareList,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
})(ShopPage);


