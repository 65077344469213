import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";

const Discountbar = (props) => {
  const [discountActive, setdiscountState] = useState(false);
  const { configs } = props;
  const discount = configs?.admin_conditions?.discount || "";

  return (
    <div className={`discount-bar ${discountActive ? "d-none" : ""}`}>
      <div className="container">
        <div className="discount-text">
          <p>
            <span>{`${discount}%`}</span> discount on All Products
          </p>
          <div
            className="discount-close"
            onClick={() => setdiscountState(!discountActive)}
          >
            <i className="icon-icon_close"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(Discountbar);
