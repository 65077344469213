import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

import "./style.scss";
import { mergeClasses } from "../../../utils";
import { useSelector } from "react-redux";

const Logo = ({
  className = "",
  to = "#",
  alt = "logo",
  sx,
  imgStyle = { objectFit: "contain", height: "100%" },
}) => {
  const logo = useSelector(
    (store) => store?.configs?.admin_conditions?.website_logo
  );
  return (
    <Link
      to={to}
      className={mergeClasses(
        "h-45 flex justify-start items-center",
        `${className}`
      )}
    >
      <img src={logo || ""} alt={alt} style={imgStyle} />
    </Link>
  );
};

export default Logo;
