import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Contact Us"} pagesub={"contact"} />
      <div className="conatct-found">
        <div>Found us via</div>
        <div>NEOTECH SERVICES LLC Sharjah Media City (Shams), Al Messaned – Al Mitsannid – Sharjah – United Arab Emirates</div>
      </div>
      <div className="conatctno-found">
        <div>Contact</div>
        <div>
          Support@neodeels.com
          <br />
          +971 523949247
        </div>
      </div>

      <div className="contactus-box">
        <form method="post" className="contact-activation" onSubmit={(e) => submitHandler(e)}>
          <h2>Get In Touch</h2>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="form-field mt-2">
                <input
                  value={forms.name}
                  type="text"
                  name="name"
                  className="form-control"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Your Name"
                />
                {validator.message("name", forms.name, "required|alpha_space")}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="form-field mt-5">
              <input
                value={forms.email}
                type="email"
                name="email"
                className="form-control"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                placeholder="Your Email"
              />
              {validator.message("email", forms.email, "required|email")}
            </div>

            <div className="fullwidth mt-5">
              <textarea
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                value={forms.message}
                type="text"
                className="form-control mt-5"
                name="message"
                placeholder="Message"
              ></textarea>
              {validator.message("message", forms.message, "required")}
            </div>
          </div>
          <div className="submit-area mt-5 mb-5">
            <button type="submit" className="btn-style-1">
              <span>Send Message</span>
            </button>
          </div>
        </form>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactForm;
