import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

const TermsandCondition = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"TERMS OF SERVICE"} pagesub={"terms and conditions"} />

      <div className="privacy-box">
        <h3 className="pb-3">TERMS AND CONDITIONS</h3>

        <p>
          By downloading or using the Website, these terms will automatically Websitely to you – you should make sure therefore that you read them carefully
          before using the Website. You’re not allowed to copy, or modify the Website, any part of the Website, or our trademarks in any way. You’re not allowed
          to attempt to extract the source code of the Website, and you also shouldn’t try to translate the Website into other languages, or make derivative
          versions. The Website itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong
          to Neodeels.
        </p>
        <p>
          Neodeels is committed to ensuring that the Website is as useful and efficient as possible. For that reason, we reserve the right to make changes to
          the Website or to charge for its services, at any time and for any reason. We will never charge you for the Website or its services without making it
          very clear to you exactly what you’re paying for.
        </p>
        <p>
          The Neodeels Website stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to
          keep your phone and access to the Website secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of
          removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to
          malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Neodeels Website won’t work properly or at
          all.
        </p>

        <p>
          You should be aware that there are certain things that Neodeels will not take responsibility for. Certain functions of the Website will require the
          Website to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Neodeels cannot take
          responsibility for the Website not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance
          left.
        </p>
        <p>
          If you’re using the Website outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will
          still Websitely. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the
          Website, or other third party charges. In using the Website, you’re accepting responsibility for any such charges, including roaming data charges if
          you use the Website outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the
          device on which you’re using the Website, please be aware that we assume that you have received permission from the bill payer for using the Website.
        </p>
        <p>
          Along the same lines, Neodeels cannot always take responsibility for the way you use the Website i.e. You need to make sure that your device stays
          charged – if it runs out of battery and you can’t turn it on to avail the Service, Neodeels cannot accept responsibility.
        </p>
        <p>
          With respect to Neodeels’s responsibility for your use of the Website, when you’re using the Website, it’s important to bear in mind that although we
          endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it
          available to you. Neodeels accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality
          of the Website.
        </p>
        <p>We accept payments online using Visa and MasterCard credit/debit card in USD</p>
        <h6>Changes to This Terms and Conditions :</h6>
        <p>
          We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you
          of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.
        </p>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default TermsandCondition;
