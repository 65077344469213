import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const LoginModalContext = createContext();

export const useLoginModal = () => useContext(LoginModalContext);

export const LoginModalProvider = ({ children }) => {
  const [loginModal, setLoginModal] = useState(false);
  const location = useLocation();
  const openLoginModal = () => {
    setLoginModal(true);
  };

  const closeLoginModal = () => {
    setLoginModal(false);
  };

  useEffect(() => {
    if (loginModal) {
      closeLoginModal();
    }
  }, [location?.pathname]);

  let valueData = {
    loginModal,
    setLoginModal,
    openLoginModal,
    closeLoginModal,
  };

  return (
    <LoginModalContext.Provider value={valueData}>
      {children}
    </LoginModalContext.Provider>
  );
};
