export const reastSelectStyles = {
  menu: (provided) => ({ ...provided, zIndex: 99 }),
  singleValue: (base) => ({ ...base, color: "#000" }),
  valueContainer: (base) => ({
    ...base,
    minHeight: "35px",
    ".css-qbdosj-Input": {
      margin: "0px",
      padding: "0px",
    },
    // color: "#000",
    // background: "#f2f2f2",
    // width: "100%",
    // borderRadius: "10px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "35px",
    borderRadius: "5px",
    background: "#f2f2f2",
    border: "none",
    // borderColor: state.isFocused ? "#253557" : "#253557",
    // boxShadow: state.isFocused ? "0 0 0 1px #666666" : "none",
    // "&:hover": {
    //   borderColor: "#000",
    // },
    "&:active": {
      borderColor: "#000",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#666666"
      : isFocused
      ? "#f2f2f2"
      : null,
    // color: isDisabled ? "#555555" : isSelected ? "#ffffff" : "#ffffff",
    // cursor: isDisabled ? "not-allowed" : "default",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#f2f2f2" : "#f5f5f5"),
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    // backgroundColor: "#444444",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    // color: "#ffffff",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    // color: "#ffffff",
    // ":hover": {
    //   backgroundColor: "#666666",
    //   color: "#ffffff",
    // },
  }),
};

export const rsTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // neutral0: "#000", // Control background color
    // neutral5: "#262626", // Menu background color
    // neutral10: "#333333", // Control border color
    // neutral20: "#444444", // Control hover border color
    // neutral30: "#666666", // Control focused border color
    // neutral50: "#aaaaaa", // Placeholder color
    // neutral80: "#ffffff", // Input text color
    // primary25: "#333333", // Option focused background color
    primary: "#f2f2f2", // Option selected background color
    // primary50: "#444444", // Option active background color
  },
});
