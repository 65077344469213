import { combineReducers } from "redux";
import productReducer, { productsInitial } from "./products";
import { cartInitial, cartReducer } from "./cart";
import { wishlistInitial, wishListReducer } from "./wishList";
import compareListReducer, { compareInitial } from "./compare";
import configsReducer, { configsInitial } from "./configs";

const appReducer = combineReducers({
  data: productReducer,
  cartList: cartReducer,
  wishList: wishListReducer,
  compareList: compareListReducer,
  configs: configsReducer,
});
// Root reducer that resets the state on logout
export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = {
      data: productsInitial, // setting this to the Initial State to logout
      cartList: cartInitial, //  setting this to the Initial State to logout .
      wishList: state?.wishList, //  setting this to the Initial State to logout .
      compareList: compareInitial, //  setting this to the Initial State to logout .
      configs: {
        ...configsInitial,
        categories: state?.configs?.categories,
        brand: state?.configs?.brand,
        ip_data: state?.configs?.ip_data,
        admin_conditions: state?.configs?.admin_conditions, // add keys here if yo want the data after logout as well 
      },
    }; // Reset the state
  }
  return appReducer(state, action);
};

export default rootReducer;
