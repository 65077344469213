import React from "react";

const ComponentError = ({
  heading = "Oops, Something Went Wrong",
  description = "There Must Be Some Error",
  onClick,
}) => {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col">
      <h4>{heading}</h4>
      <p>{description}</p>
      {onClick && (
        <button className="btn btn-primary" onClick={onClick}>
          Refresh
        </button>
      )}
    </div>
  );
};

export default ComponentError;
