import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { totalPrice } from "../../utils";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import search from "../../images/icon/search.png";
import { useSearchModal } from "../../Context/SearchModalContext";
import axios from "axios";
import { addUserCart } from "../../store/actions/action";
import { useCart } from "../../Context/CartContext";
import PulseLoader from "react-spinners/PulseLoader";
import MoonLoader from "react-spinners/MoonLoader";
import Logo from "../UiStyle/Logo";

const HeaderMiddle = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
    openModal();
  };

  const { carts, wish, configs } = props;
  const { openModal } = useSearchModal();
  const { cart } = useCart();

  const [language, setlanguage] = React.useState("");

  const handleChange = (event) => {
    setlanguage(event.target.value);
  };

  return (
    <div className="header-middle">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="navbar-header">
              <Logo to={"/"} className="navbar-brand" />
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-6">
            <form onSubmit={SubmitHandler} onClick={openModal} className="middle-box">
              {/* <div className="category">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={language}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">categorys</MenuItem>
                    <MenuItem value={2}>Medicine</MenuItem>
                    <MenuItem value={3}>Equipment</MenuItem>
                    <MenuItem value={4}>Doctor</MenuItem>
                    <MenuItem value={5}>Medical</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="search-box">
                <div className="input-group">
                  <button className="search-btn" type="submit">
                    <i>
                      <img src={search} alt="" />
                    </i>
                  </button>
                  <input type="search" className="form-control" placeholder="Search Products" />
                </div>
              </div>
            </form>
          </div>
          <div className="col-6 col-md-6 col-lg-3">
            <div className="middle-right">
              <ul>
                <li>
                  <Link to="/wishlist">
                    <i className="icon-heart">
                      <span className="cart-count">{wish.length}</span>
                    </i>
                  </Link>
                </li>
                <li>
                  {cart?.loading ? (
                    <Link to="#" className="relative">
                      <MoonLoader color="" size={16} className="absolute" style={{ top: "40%", left: "25%" }} />
                      <i className="icon-cart">
                        <span className="cart-count">
                          <MoonLoader size={8} color="#fafafa" />
                        </span>
                      </i>
                      <p>
                        <small>{carts?.length} items</small>${totalPrice(carts)}
                      </p>
                    </Link>
                  ) : (
                    <Link to="/cart">
                      <i className="icon-cart">
                        <span className="cart-count">{carts?.length}</span>
                      </i>
                      <p>
                        <small>{carts.length} items</small>${totalPrice(carts)}
                      </p>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    configs: state?.configs,
    token: state?.configs?.token,
    carts: state?.cartList?.cart,
    wish: state?.wishList?.w_list,
    compare: state?.compareList?.compare_list,
  };
};

export default connect(mapStateToProps, {
  addUserCart,
})(HeaderMiddle);
