import React from 'react'

const AnimateMarquee = (props) => {
    return (
      <div className="animate-marque-sec">
        <div className="animate-marque">
          <div className="marquee">
            <div className="track">
              <div className="content">
                <h1>
                  <span>Neodeels</span>
                  <span>Gift</span>
                  <span>Cards</span>
                  <span>Special</span>
                  <span>Deals</span>
                  <span>Neodeels</span>
                  <span>Gift</span>
                  <span>Cards</span>
                  <span>Exclusive</span>
                  <span>Offers</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AnimateMarquee;