import React, { Fragment, useMemo, useState } from "react";
import {
  Dialog,
  Grid,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Rating,
} from "@mui/material";
import DeleteIcon from "../../icons/DeleteIcon";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";
import { checkLoggedIn } from "../../utils";
import { MoonLoader } from "react-spinners";
import BuyNowIcon from "../../icons/BuyNowIcon";
import _ from "lodash";
import { useLoginModal } from "../../Context/LoginModalContext";

const ViewModal = ({
  maxWidth = "xl",
  open,
  onClose,
  addToCartProduct,
  product,
  carts,
  removeFromCart,
  decrementQuantity,
  ...props
}) => {
  const navigate = useNavigate();
  const { openLoginModal } = useLoginModal();
  const [loading, setLoading] = useState(false); // Loading state

  const inCart = carts?.find((cart) => cart.id === product?.id);

  // Function to handle adding to cart
  const handleAddToCart = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await addToCartProduct(product); // Wait for the API call to complete
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      setTimeout(() => {
        addToCartProduct(product);
        setLoading(false);
      }, 1000);
    }
  };

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      await props?.incrementQuantity(product.id); // Wait for the API call to complete
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      setTimeout(() => {
        props?.incrementQuantity(product.id);
        setLoading(false);
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoading(true); // Start loading
      if (inCart.qty === 1) {
        await removeFromCart(product.id); // Wait for the remove action
      } else {
        await decrementQuantity(product.id); // Wait for decrement action
      }
      setLoading(false); // Stop loading
    } else {
      setLoading(true);
      if (inCart.qty === 1) {
        setTimeout(() => {
          removeFromCart(product.id);
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          decrementQuantity(product.id);
          setLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <Fragment>
      <Dialog fullWidth={true} open={open} className="modalWrapper cart-quickview" maxWidth={maxWidth}>
        <DialogTitle id="scroll-dialog-title">
          <h4>Quick view</h4>
          <button className="btn-close" onClick={onClose}>
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <Grid className="modalBody modal-body relative">
            <div className="product-details">
              <div className="row align-items-center ">
                <div className="col-lg-5 single-product">
                  <div className="product-single-image">
                    {/* <div className="item"> */}
                    <ul className="tag-wrap" style={{ zIndex: 1 }}>
                      {product?.badges?.length > 0 &&
                        product?.badges?.map((badge, index) => {
                          return (
                            <li className={`tag ${badge}`} key={badge + index}>
                              {badge}
                            </li>
                          );
                        })}
                    </ul>
                    <ReactImageGallery
                      showPlayButton={false}
                      autoPlay={false}
                      items={product?.proimg?.map((image) => ({
                        original: image?.src,
                        thumbnail: image?.src,
                      }))}
                    />
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="product-single-content">
                    <h5>{product?.title}</h5>
                    <p>{product?.description}</p>

                    <div className="flex items-center gap-10 mb-10">
                      <Rating name="read-only" precision={0.5} value={+product?.five_start_rating} readOnly className="mt-2" />{" "}
                      <span className="mt-2">{product?.five_start_rating || 0}</span>
                    </div>

                    <span className="present-price">
                      {product?.iseuro ? "€" : "$"}
                      {product?.price}
                    </span>
                    <del className="old-price">
                      {product?.iseuro ? "€" : "$"}
                      {product?.delPrice}
                    </del>

                    {!_?.isEmpty(product?.size) && (
                      <div className="product-filter-item color filter-size">
                        {/* <div className="color-name">
                          <span>Size :</span>
                          <ul>
                            {product?.size?.map((siz) => (
                              <li className="color">
                                <input
                                  id="wa1"
                                  type="radio"
                                  name="size"
                                  value="30"
                                />
                                <label htmlFor="wa1">{siz}</label>
                              </li>
                            ))}
                          </ul>
                        </div> */}
                      </div>
                    )}

                    <div className="stock">
                      <ul>
                        <li>In stock : {product?.stock}</li>
                      </ul>
                    </div>
                    <div className="category">
                      <p className="mb-1 mt-2">Category : {product?.category}</p>

                      <p className="mb-1">Brand : {product?.brand}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <button
            className="buy-now-btn"
            data-tooltip-id="buy-now-button"
            data-tooltip-content="Buy Now"
            data-tooltip-place="top"
            onClick={async () => {
              if (!checkLoggedIn()) {
                await handleAddToCart();
                navigate("/cart");
              } else if (inCart) {
                navigate("/cart");
              } else {
                await handleAddToCart();
                navigate("/cart");
              }
            }}
            disabled={loading} // Disable button while loading
          >
            {loading ? <MoonLoader size={16} color="#fafafa" /> : <BuyNowIcon />}
            Buy Now
          </button>
          {inCart ? (
            <div className="flex justify-center items-center gap-20 w-1/2">
              <button
                className="decQtybutton w-full"
                onClick={handleDecrementQuantity}
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <MoonLoader size={16} color="#fafafa" /> // Show spinner
                ) : inCart.qty === 1 ? (
                  <DeleteIcon />
                ) : (
                  "-"
                )}
              </button>
              <h2>{inCart?.qty}</h2>
              <button
                className="incQtybutton w-full"
                onClick={handleIncrementQuantity}
                disabled={loading} // Disable button while loading
              >
                {loading ? <MoonLoader size={16} color="#fafafa" /> : "+"}
              </button>
            </div>
          ) : (
            <button
              className="cart-btn-full"
              data-tooltip-id="cart-tooltip"
              data-tooltip-content="add to cart"
              data-tooltip-place="left"
              onClick={handleAddToCart}
              disabled={loading} // Disable button while loading
            >
              {loading ? <MoonLoader size={16} color="#fafafa" /> : <i className="icon-cart"></i>}
            </button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export default ViewModal;
