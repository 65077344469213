import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import CheckoutSection from "../../components/CheckoutSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { connect } from "react-redux";
import Footer from "../../components/footer/Footer";
import { updateConfigs } from "../../store/actions/action";

const CheckoutPage = ({ cartList, ...props }) => {
  return (
    <Fragment>
      <Navbar />
      {/* <PageTitle pageTitle={'Checkout'} pagesub={'Checkout'}/>  */}
      <CheckoutSection cartList={cartList} {...props} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    cartList: state.cartList.cart,
    configs: state.configs,
    token: state.configs.token,
    symbol: state.data.symbol,
  };
};

export default connect(mapStateToProps, {
  updateConfigs,
})(CheckoutPage);
