import { Rating } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const HorizontalProductCard = ({ product, key, ...props }) => {
  return (
    <div className="horizontal-product-item mb-0">
      <div className="product-image">
        <img src={product.proimg?.[0]?.src} alt={"image - " + product.proimg?.[0]?._id} />
      </div>
      <div className="product-info">
        <Link to={`/product-single/${product.slug}`}>{product.title} </Link>
        <div className="flex items-center gap-10 p-0 m-0">
          <Rating size="small" name="read-only" precision={0.5} value={+product?.five_start_rating} readOnly />{" "}
          <span style={{ fontSize: "10px" }}>{product?.five_start_rating || 0} star rating</span>
        </div>
        <div className="price pt-1">
          <span className="present-price">
            {product?.iseuro ? "€" : "$"}
            {product.price}
          </span>
          <del className="old-price">
            {product?.iseuro ? "€" : "$"}
            {product.delPrice}
          </del>
        </div>
      </div>
    </div>
  );
};

export default HorizontalProductCard;
