import React from "react";

const prices = [
  {
    id: 1,
    minPrice: 100,
    maxPrice: 200,
  },
  {
    id: 2,
    minPrice: 300,
    maxPrice: 400,
  },
  {
    id: 3,
    minPrice: 400,
    maxPrice: 500,
  },
  {
    id: 4,
    minPrice: 600,
    maxPrice: 700,
  },
  {
    id: 5,
    minPrice: 800,
    maxPrice: null,
  },
];

const sizes = ["s", "m", "l", "xl", "xxl"];
// const brands = ["Squarely", "Pekos", "Pharma"];
// const categorys = ["Medkits", "Surgical Mask", "Safety Guard", "Pharmacy",];

const FilterSidebar = ({
  filter,
  changeHandler,
  priceChangeHandler,
  configs,
  ...props
}) => {
  console.log({ configs });
  return (
    <div className="col-lg-3">
      <div className="shop-filter-wrap">
        <div className="filter-item">
          <div
            className={`shop-filter-item category-widget h-400 ${
              configs?.categories?.length > 8 ? "scrollable-div" : ""
            }`}
          >
            <h2>Shop By Category</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All Category
                  <input
                    checked={filter?.category === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="category"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {configs?.categories?.map((category, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {category?.name}
                    <input
                      checked={filter?.category === category?.name}
                      type="radio"
                      value={category?.name}
                      onChange={changeHandler}
                      name="category"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item category-widget scrollable-div h-300">
            <h2>Shop By Brand</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All brand
                  <input
                    checked={filter?.brand === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="brand"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {configs?.brand?.map((brand, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {brand}
                    <input
                      checked={filter.brand === brand}
                      type="radio"
                      value={brand}
                      onChange={changeHandler}
                      name="brand"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Price</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All prices
                  <input
                    type="radio"
                    value={{}}
                    checked={!filter.minPrice && !filter.maxPrice} // Checked if no price is selected
                    name="price"
                    onChange={() => priceChangeHandler({})}
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {prices.map((price, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    ${price.minPrice}{" "}
                    {price.maxPrice ? `- $${price.maxPrice}` : " and more"}
                    <input
                      checked={
                        +filter.minPrice === price.minPrice ||
                        +filter.maxPrice === price.maxPrice
                      } // Check based on selected price range
                      type="radio"
                      value={price}
                      onChange={() => priceChangeHandler(price)}
                      name="price"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Size</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All Size
                  <input
                    checked={filter.size === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="size"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {sizes.map((size, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {size}
                    <input
                      checked={filter.size === size}
                      type="radio"
                      value={size}
                      onChange={changeHandler}
                      name="size"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FilterSidebar;
