import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

const PrivacyForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"PRIVACY POLICY"} pagesub={"privacy"} />

      <div className="privacy-box">
        <h3 className="pb-3">PRIVACY</h3>

        <p className="">Neodeels built the Neodeels website as a Commercial website. This SERVICE is provided by Neodeels and is intended for use as is.</p>
        <p>
          This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use
          our Service
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we
          collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy
          Policy.
        </p>

        <p>
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Neodeels unless otherwise defined
          in this Privacy Policy.
        </p>

        <h6>WHAT INFORMATION WE MAY COLLECT FROM YOU :</h6>

        <p>We may collect and process the following Information about you:</p>

        <p>
          a.Information that you provide by filling in forms on our Platform, including information provided at the time of registering to use our Platform and
          other co- registrations (e.g. social media logins), subscribing to our Services, posting material or requesting further services;
        </p>
        <p>
          b.the Information you provide when you enter a competition or promotion via our Platform, provide reviews, testimonials or feedback on our Platform;
        </p>
        <p>c.Information you provide us, or that we may collect from you, when you report a problem with our Platform;</p>
        <p>d.a record of correspondence if you contact us;</p>
        <p>e.general, aggregated, demographic and non-personal Information;</p>
        <p>
          f.if you download or use our mobile websitelication, we may have access to details about your location and your mobile device, including a unique
          identifier for your device;
        </p>
        <p>g.details of transactions you carry out through our Platform and of the fulfilment of your orders;</p>
        <p>
          h.details about your computer, including but not limited to your IP address, operating system and browser type, as well as information about your
          general internet usage (e.g. by using technology that stores information on or gains access to your device, such as cookies, tracking pixels, web
          beacons, etc., (together, “Cookies”));
        </p>
        <p>i.your email address from a third party if you indicate that you have consented to that third party sharing your Information with us; and</p>
        <p>j.any other Information we consider necessary to enhance your experience on the Platform.</p>

        <p>*All credit/debit card details and personally identifiable information will NOT be stored, sold, shared, rented, or leased to any third parties.</p>

        <h6>HOW WE WILL USE YOUR INFORMATION :</h6>

        <p>We may use Information held about you in the following ways:</p>
        <p>
          a.to provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be
          contacted for such purposes;
        </p>
        <p>b.to provide you with location-based services, such as advertising, search results and other personalised content;</p>
        <p>c.to improve our Services and to deliver a better and more personalised service to you;</p>
        <p>d.to ensure that content from our Platform is presented in the most effective manner for you and the device you use to access our Platform;</p>
        <p>e.to notify you about changes to our Services;</p>
        <p>f.for any other reason which we deem necessary to enhance your experience of the Platform;</p>
        <p>
          g.to administer and manage our incentives programs and fulfil your requests for incentives,and/or to allow you to participate in sweepstakes and to
          notify you if you are a sweepstakes winner.
        </p>
        <h6>HOW CAN YOU ACCESS AND AMEND YOUR INFORMATION?</h6>

        <p>
          You are able to access a broad range of information about your account and your interactions with the Platform for the purpose of viewing and, in
          certain cases, updating your Information.
        </p>

        <h6>Examples of information you can access easily at the Platform include:</h6>
        <p>a.up-to-date information regarding recent orders;</p>
        <p>b.personally identifiable information (including name, e-mail, password, communications and personalised advertising preferences);</p>
        <p>c.payment settings (including credit card information); and</p>
        <p>
          You can opt-out of receiving future marketing communications from us at any time by adjusting your customer communication preferences, through the
          unsubscribe link within the email communication. For marketing via your mobile websitelication, you will need to adjust your notifications settings in
          the general section of your mobile.
        </p>
        <p>
          Also, our system will place cookies when you log on to our Platform and this is to ensure you have an enjoyable user experience and are able to
          utilise all aspects of the Platform. You may disable Cookies by changing the settings on your browser. If you disable Cookies, it will affect how our
          Platform works and you may not be able to access or use certain areas of our Platform or full functionality. For example, performance cookies collect
          information about how you use the Site, for instance, which pages you visit most often, which allows us to provide you with targeted and relevant
          choices that enhance your Site experience.
        </p>
        <p>
          We may retain a copy of your Information for compliance reasons. When you update Information, we may retain a copy of the prior version for our
          records.
        </p>
        <h6>Data retention and deletion</h6>
        <p>
          RetentionWe keep your personal data only as long as necessary to provide you with the Neodeels Service and for Neodeels’s legitimate and essential
          business purposes, such as:
        </p>
        <p>a.maintaining the performance of the Neodeels Service</p>
        <p>b.making data-driven business decisions about new features and offerings</p>
        <p>c.complying with our legal obligations</p>
        <p>d.resolving disputes</p>
        <p>
          DeletionIf you close or request that we close your account, we’ll delete or anonymise your personal data so it no longer identifies you, unless we’re
          required to keep something or we still need to use it for a legally justifiable reason.
        </p>
        <p>Here are some examples of situations where we’re legally allowed or required to keep some of your personal data:</p>
        <p>a.if there’s an unresolved issue relating to your account, such as an outstanding credit or unresolved claim or dispute</p>
        <p>b.for our legal, tax, audit and accounting obligations</p>
        <p>c.for our legitimate business interests such as fraud prevention or to maintain security.</p>
        <p>Accordingly, you can request modification or deletion of your information by contacting Neodeels’s customer service</p>
        <h6>Information Collection and Use :</h6>
        <p>
          For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information
          that we request will be retained by us and used as described in this privacy policy.
        </p>
        <p>The website does use third party services that may collect information used to identify you.</p>
        <p>Link to privacy policy of third party service providers used by the website Google Play Services</p>
        <h6>Log Data :</h6>
        <p>
          We want to inform you that whenever you use our Service, in a case of an error in the website we collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name,
          operating system version, the configuration of the website when utilizing our Service, the time and date of your use of the Service, and other
          statistics.
        </p>
        <h6>Cookies :</h6>
        <p>
          Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites
          that you visit and are stored on your device’s internal memory.
        </p>
        <p>
          This Service does not use these “cookies” explicitly. However, the website may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your
          device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
        </p>
        <h6>Governing Law and Jurisdiction :</h6>
        <p>
          Any purchase, dispute, or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of the
          UAE.
        </p>
        <h6>Delivery/Shipping/Service Policy :</h6>
        <p>
          Neodeels will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with
          the law of UAE.
        </p>
        <h6>Service Providers :</h6>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>
        <p>•To facilitate our Service</p>
        <p>•To provide the Service on our behalf</p>
        <p>•To perform Service-related services</p>
        <p>•or To assist us in analyzing how our Service is used</p>
        <p>
          We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned
          to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
        </p>
        <h6>Security :</h6>
        <p>
          We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But
          remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its
          absolute security.
        </p>
        <h6>Links to Other Sites :</h6>
        <p>
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites
          are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </p>
        <h6>Children’s Privacy :</h6>
        <p>
          These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In
          the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent
          or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary
          actions.
        </p>
        <h6>Changes to This Privacy Policy :</h6>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any
          changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
        </p>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default PrivacyForm;
